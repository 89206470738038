import React from 'react';
import PropTypes from 'prop-types';

const ArrowDropUpIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 20 20">
    <path d="M0 15a.5.5 0 00.853.354l8.646-8.646 8.646 8.646a.5.5 0 00.707-.707l-9-9a.5.5 0 00-.707 0l-9 9a.498.498 0 00-.146.354z" />
  </svg>
);

ArrowDropUpIcon.propTypes = {
  className: PropTypes.string
};
ArrowDropUpIcon.displayName = 'ArrowDropUpIcon';
export default ArrowDropUpIcon;
