import React from 'react';
import PropTypes from 'prop-types';
import palette from '../../assets/scss/_palette.scss';

const LogoutIcon = ({ onClick, className, width = '15px', height = '15px' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 3.969 3.969"
    onClick={onClick}
    data-testid={'logout'}
    fill={palette.greyTxt}
  >
    <defs>
      <clipPath clipPathUnits="userSpaceOnUse" id="logout_svg__a">
        <path d="M0 768h1024V0H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#logout_svg__a)" transform="matrix(.44864 0 0 -.45015 -382.729 306.882)">
      <path
        d="M855.708 679.638a2.918 2.918 0 01-1.158-3.24c.201-.714.744-1.467 1.465-1.852 1.168-.623 2.679-.317 3.472.347.672.56 1.223 1.416 1.041 2.855-.054.44-.34.883-.5 1.12-.113.162-.375.81-.886.77-.137-.01-.424-.148-.426-.463-.002-.34.504-.65.656-.926.936-1.697-.643-3.549-2.393-3.125-1.226.298-2.089 1.934-1.349 3.202.152.26.832.626.771 1.041-.043.305-.377.375-.617.31-.047-.014-.049-.019-.076-.039m1.58.771c-.226-.33-.131-.843-.154-1.35-.035-.77-.217-1.698.117-2.044.215-.01.418-.008.539.077.205.901.188 2.23.154 3.24-.113.143-.484.222-.656.077m-.076 1.312c2.361.185 4.236-1.493 4.629-3.51.416-2.124-.672-3.844-1.928-4.63-.701-.437-1.726-.709-2.701-.656-2.449.134-4.424 2.274-4.09 5.17.156 1.353 1.227 2.598 2.277 3.163.541.293 1.047.403 1.813.463"
        fill="#fff"
      />
      <path
        d="M857.944 680.332c.033-1.012.051-2.339-.154-3.24-.12-.085-.325-.088-.539-.077-.334.345-.153 1.273-.118 2.044.024.506-.072 1.02.155 1.35.172.145.543.066.656-.077"
        fillRule="evenodd"
      />
      <path
        d="M855.784 679.676c.24.065.574-.004.617-.309.061-.415-.619-.782-.77-1.04-.742-1.269.122-2.905 1.348-3.203 1.75-.424 3.328 1.428 2.393 3.125-.152.276-.658.585-.656.926.002.314.29.453.425.463.512.04.774-.608.887-.772.16-.235.445-.678.5-1.118.182-1.439-.369-2.295-1.04-2.855-.794-.664-2.306-.97-3.474-.347-.72.385-1.263 1.138-1.464 1.852a2.919 2.919 0 001.158 3.24c.027.02.03.025.076.038"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

LogoutIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};
LogoutIcon.displayName = 'LogoutIcon';
export default LogoutIcon;
