import React from 'react';
import PropTypes from 'prop-types';
import { CustomCheckbox } from '../table-user/customCheckbox';
import { CrossIcon } from '../../app/icons/crossIcon';
import { Button } from '../../app/button/button';
import TickIcon from '../../app/icons/tickIcon';

export const AccessTab = ({
  applications,
  isInSelectedApps,
  onSelectApp,
  accessTabError,
  onSubmit,
  disabled
}) => {
  return (
    <>
      <div className="createEdit__tabs__access">
        {applications.map(({ code, name }, index) => {
          return (
            <div key={index} className="createEdit__tabs__access__item">
              <CustomCheckbox
                testId={code}
                checked={isInSelectedApps(code)}
                onClick={onSelectApp(code)}
              />
              <label onClick={onSelectApp(code)}>{name}</label>
            </div>
          );
        })}
      </div>
      {accessTabError && (
        <div data-testid="error" className="createEdit__tabs__access__error">
          <CrossIcon />
          <label className="createEdit__tabs__access__error__message">
            Vous devez sélectionner au moins une application
          </label>
        </div>
      )}
      <div className="createEdit__tabs__access__button">
        <Button
          className="createEdit__tabs__access__button button--black"
          onClick={onSubmit}
          disabled={disabled}
          testId="save"
        >
          <TickIcon className="button--withIcon" />
          Sauvegarder
        </Button>
      </div>
    </>
  );
};

AccessTab.propTypes = {
  applications: PropTypes.array,
  isInSelectedApps: PropTypes.func,
  onSelectApp: PropTypes.func,
  accessTabError: PropTypes.bool,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func
};
