import { config } from '../../adalConfig';

export function usersConfig() {
  return {
    api: {
      users: {
        url: config.serverUrl + '/users'
      },
      update: {
        url: config.serverUrl + '/users/'
      },
      language: {
        url: config.serverUrl + '/users/language'
      },
      activate: {
        url: config.serverUrl + '/users/activate'
      },
      deactivate: {
        url: config.serverUrl + '/users/deactivate'
      },
      remove: {
        url: config.serverUrl + '/users/remove'
      },
      get: {
        url: config.serverUrl + '/users/current'
      },
      import: {
        url: config.serverUrl + '/users/import'
      },
      export: {
        url: config.serverUrl + '/users/export'
      },
      searchUsers: { url: `${config.serverUrl}/users/suggestions` },
      searchCompanies: { url: `${config.serverUrl}/users/companies` }
    }
  };
}
