import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './../app/store';
import Hive from './hive/hive';
import ImportUser from './import-user/import-user';
import ExportUser from './export-user/export-user';
import TableUser from './table-user/table-user';
import ErrorPage from '../app/errorPage/errorPage';
import Applications from './applications/applications';

const Router = () => {
  return (
    <Provider store={store}>
      <Switch>
        <Route exact path="/" component={Hive} />
        <Route exact path="/import-user" component={ImportUser} />
        <Route exact path="/list-user" component={TableUser} />
        <Route exact path="/export-user" component={ExportUser} />
        <Route exact path="/applications" component={Applications} />
        <Route render={props => <ErrorPage {...props} is404 />} />
      </Switch>
    </Provider>
  );
};

export default Router;
