import React from 'react';
import PropTypes from 'prop-types';

export const Button = ({ type, children, onClick, testId, className, disabled }) => {
  return (
    <button
      className={`button ${className && className}`}
      type={type}
      onClick={onClick}
      data-testid={testId}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onClick: PropTypes.func,
  testId: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool
};
Button.displayName = 'Button';
