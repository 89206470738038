import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './app';
import { loadConfig } from './config';
import { authContext, setAuthContext, config } from './adalConfig';
import { runWithAdal } from 'react-adal';
import { getUser } from './services/users/usersApi';
import ReactModal from 'react-modal';
import ErrorPage from './app/errorPage/errorPage';

ReactModal.setAppElement('#root');

loadConfig().then(data => {
  config.name = data.name;
  config.token = data.token;
  config.clientId = data.clientId;
  config.redirectUri = data.redirectUri;
  config.apimUrl = data.apimUrl;
  config.serverUrl = data.serverUrl;
  config.subscriptionKey = data.subscriptionKey;

  setAuthContext();

  const needLogin = config.name === 'dev';

  runWithAdal(
    authContext,
    () => {
      if (!window.location.href.endsWith('error')) {
        getUser()
          .then(data => {
            ReactDOM.render(
              <HashRouter>
                <App />
              </HashRouter>,
              document.getElementById('root')
            );
          })
          .catch(() => {
            ReactDOM.render(<ErrorPage />, document.getElementById('root'));
          });
      }
    },
    needLogin
  );
});

module.hot.accept();
