import React, { Component, createRef } from 'react';
import Router from '../components/router';
import ErrorPage from './errorPage/errorPage';
import Header from '../components/header/header';
import { LanguageProvider } from './languageContext';

export const NotificationContext = React.createContext({});
export const NotificationProvider = NotificationContext.Provider;

const notificationRef = createRef();
const displayNotifictaion = (message, level = 'success', autoDismiss = 7) => {
  const notification = notificationRef.current;
  notification.clearNotifications();
  notification.addNotification({
    message,
    level,
    position: 'tr',
    dismissible: 'button',
    autoDismiss,
    timeout: 80000
  });
};
class App extends Component {
  state = { appInError: false };

  // eslint-disable-next-line handle-callback-err
  static getDerivedStateFromError(error) {
    return { appInError: true };
  }

  render() {
    return (
      <>
        {this.state.appInError ? (
          <ErrorPage />
        ) : (
          <NotificationProvider value={{ notificationRef, displayNotifictaion }}>
            <LanguageProvider>
              <Header />
              <Router />
            </LanguageProvider>
          </NotificationProvider>
        )}
      </>
    );
  }
}

export default App;
