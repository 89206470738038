import React from 'react';
import PropTypes from 'prop-types';
import { CustomCheckbox } from './customCheckbox';
import { useSelector, useDispatch } from 'react-redux';
import { SELECT } from './reducer';

const RowUser = ({ user, selectedAll, onEditUser }) => {
  const dispatch = useDispatch();
  const getSelectedAll = useSelector(state => state.tableUser.selectedAll);
  const isUserSelected = useSelector(state => state.tableUser.selectedUsers.includes(user.ad));

  const onSelectUser = e => {
    e.preventDefault();
    dispatch({ type: SELECT, ad: user.ad });
  };

  return (
    <tr className={'tableUser__row'}>
      <td className="tableUser__cell">
        <CustomCheckbox
          checked={getSelectedAll || isUserSelected}
          onClick={onSelectUser}
          testId={getSelectedAll || isUserSelected ? 'rowSelected' : 'row'}
        />
      </td>
      <td onClick={onEditUser} className="tableUser__cell" data-testid="ad">
        {user.ad}
      </td>
      <td onClick={onEditUser} className="tableUser__cell">
        <span data-testid="firstName">{user.firstName}</span>
      </td>
      <td onClick={onEditUser} className="tableUser__cell">
        <span data-testid="lastName">{user.lastName}</span>
      </td>
      <td onClick={onEditUser} className="tableUser__cell">
        <span data-testid="role">{user.role.name}</span>
      </td>
      <td onClick={onEditUser} className="tableUser__cell">
        <span data-testid="company">{user.company}</span>
      </td>
      <td onClick={onEditUser} className="tableUser__cell">
        <span data-testid="mail">{user.mail}</span>
      </td>
      <td onClick={onEditUser} className="tableUser__cell">
        <span data-testid="country">{user.country}</span>
      </td>
      <td onClick={onEditUser} className="tableUser__cell">
        <span data-testid="city">{user.city}</span>
      </td>
      <td onClick={onEditUser} className="tableUser__cell">
        <span data-testid="activated">{user.activated ? 'Activé' : 'Désactivé'}</span>
      </td>
    </tr>
  );
};

RowUser.propTypes = {
  user: PropTypes.object,
  selectedAll: PropTypes.bool,
  onEditUser: PropTypes.func
};

RowUser.displayName = 'RowUser';
export default RowUser;
