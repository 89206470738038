import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { SELECT_ALL, UNSELECT_ALL, CHANGE_PAGE, LOAD } from './reducer';
import {
  DB_ACTIVATED,
  DB_AD,
  DB_CITY,
  DB_COMPANY,
  DB_COUNTRY,
  DB_FIRSTNAME,
  DB_LASTNAME,
  DB_MAIL,
  DB_ROLE,
  ORDER_ASC,
  ORDER_DESC
} from '../../utils/userConstant';
import Loader from '../loader';
import ReactPaginate from 'react-paginate';
import { Layout } from '../../app/layout';
import RowUser from './row-user';
import CreateEdit from '../user/createEdit';
import {
  activateUsers,
  deactivateUsers,
  deleteUsers,
  getUserByAd,
  getUsers,
  getUsersByCompany
} from '../../services/users/usersApi';
import ArrowPreviousIcon from '../../app/icons/arrowPreviousIcon';
import ArrowNextIcon from '../../app/icons/arrowNextIcon';
import ArrowDropUpIcon from '../../app/icons/arrowDropUp';
import ArrowDropDownIcon from '../../app/icons/arrowDropDown';
import ArrowsIcon from '../../app/icons/arrows';
import SearchWindow from './searchWindow/searchWindow';
import { CustomCheckbox } from './customCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { SelectUsersMenu } from './selectUsersMenu';
import { EraserIcon } from '../../app/icons/eraserIcon';
import { Button } from '../../app/button/button';
import { AddUserIcon } from '../../app/icons/addUserIcon';
import { ActivateIcon } from '../../app/icons/activateIcon';
import { DeactivateIcon } from '../../app/icons/deativateIcon';
import { DeleteUserIcon } from '../../app/icons/deleteUserIcon';
import { NotificationContext } from '../../app';
import { useListUserReducer } from './useListUserReducer';

const TableUser = () => {
  const dispatchRedux = useDispatch();
  const selectAll = useCallback(() => dispatchRedux({ type: SELECT_ALL }), [dispatchRedux]);
  const unselectAll = useCallback(() => dispatchRedux({ type: UNSELECT_ALL }), [
    dispatchRedux
  ]);
  const load = users => dispatchRedux({ type: LOAD, users });
  const changePage = useCallback(() => dispatchRedux({ type: CHANGE_PAGE }), [dispatchRedux]);

  const { displayNotifictaion } = useContext(NotificationContext);

  const users = useSelector(state => state.tableUser.users);
  const allUsersAreSelected = useSelector(state => state.tableUser.selectedAll);
  const selectedUsers = useSelector(state => state.tableUser.selectedUsers).length;
  const getAdSelectedUsers = useSelector(state => state.tableUser.selectedUsers);
  const allPagesSelected = useSelector(state => state.tableUser.selectAllPages);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userModal, setUserModal] = useState();
  const [searchByFirstNameOpen, setSearchByFirstNameOpen] = useState(false);
  const [searchByLastNameOpen, setSearchByLastNameOpen] = useState(false);
  const [searchByEmailOpen, setSearchByEmailOpen] = useState(false);
  const [searchByCompanyOpen, setSearchByCompanyOpen] = useState(false);
  const [searchByAdOpen, setSearchByAdOpen] = useState(false);

  const searchRef = useRef();
  const [state, dispatch] = useListUserReducer(changePage, load);

  useEffect(() => {
    dispatch({ type: 'CHANGE_SPINNER' });
    const loadUsers = async () => {
      try {
        const data = await getUsers(state.currentPage, state.sort, state.order);
        dispatch({ type: 'LOAD_USERS_SUCESSFUL', data });
      } catch (e) {
        dispatch({ type: 'LOAD_USERS_FAILURE' });
      }
    };

    loadUsers();
    document.addEventListener('mousedown', handleClickOutsideSearchWindow);
    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutsideSearchWindow);
    };
  }, []);

  const handleClickOutsideSearchWindow = event => {
    if (searchRef.current && searchRef.current.contains(event.target)) {
      return;
    }
    setSearchByFirstNameOpen(false);
    setSearchByLastNameOpen(false);
    setSearchByEmailOpen(false);
    setSearchByCompanyOpen(false);
    setSearchByAdOpen(false);
  };

  useEffect(() => {
    if (state.searchTypeMaster === 'company') {
      const loadUserByCompany = async () => {
        try {
          dispatch({ type: 'CHANGE_SPINNER' });
          const data = await getUsersByCompany(
            state.searchValue,
            state.currentPage,
            state.sort,
            state.order
          );
          dispatch({ type: 'CHANGE_SPINNER' });
          dispatch({
            type: 'LOAD_USERS_SUCESSFUL',
            data,
            filter: true,
            spinner: false,
            company: state.searchValue
          });
        } catch (e) {
          dispatch({ type: 'LOAD_USERS_FAILURE' });
        }
      };
      loadUserByCompany();
      dispatch({ type: 'RESET_RELOAD_USER_LIST' });
      return;
    }
    if (state.searchTypeMaster === 'user') {
      const loadUserByAd = async () => {
        try {
          dispatch({ type: 'CHANGE_SPINNER' });
          const data = await getUserByAd(state.searchValue);
          dispatch({ type: 'LOAD_USERS_SUCESSFUL', data, filter: true, spinner: false });
        } catch (e) {
          dispatch({ type: 'LOAD_USERS_FAILURE' });
        }
      };
      loadUserByAd();
      dispatch({ type: 'RESET_RELOAD_USER_LIST' });
      return;
    }

    if (state.reloadUserList) {
      const loadUsers = async () => {
        try {
          dispatch({ type: 'CHANGE_SPINNER' });
          const data = await getUsers(state.currentPage, state.sort, state.order);
          dispatch({ type: 'LOAD_USERS_SUCESSFUL', data, spinner: false });
        } catch (e) {
          dispatch({ type: 'LOAD_USERS_FAILURE' });
        }
      };
      loadUsers();
      dispatch({ type: 'RESET_RELOAD_USER_LIST' });
    }
  }, [
    state.currentPage,
    state.order,
    state.sort,
    state.searchTypeMaster,
    state.searchValue,
    state.reloadUserList
  ]);

  const handlePageClickMaster = page => {
    dispatch({ type: 'CHANGE_PAGE', page });
    reloadUsers();
  };

  const onSelectSearchElement = (selectedElement, searchType) => {
    dispatch({ type: 'CHANGE_SPINNER' });
    dispatch({
      type: 'FILTER_LIST',
      searchValue: selectedElement,
      currentPage: 0,
      searchType
    });
    unselectAll();
  };

  const onClearSearch = () => {
    dispatch({ type: 'CLEAR_SEARCH' });
    reloadUsers();
    unselectAll();
  };

  const handleSort = (sort, order, page) => {
    dispatch({ type: 'SORT', sort, order, page });
    reloadUsers();
  };

  const handleSortClick = name => () => {
    let searchOrder = ORDER_ASC;
    if (state.sort === name) {
      if (state.order === ORDER_DESC) {
        searchOrder = ORDER_ASC;
      } else {
        searchOrder = ORDER_DESC;
      }
    }
    handleSort(name, searchOrder, 0);
  };

  const handleCloseAndRefreshUsers = () => {
    closeModal();
    handleSort(state.sort, state.order, state.currentPage);
  };

  const handlePageClick = selectedItem => handlePageClickMaster(selectedItem.selected);

  const getArrowIcon = name => {
    let classChevron = <ArrowsIcon className="tableUser__sort--double" />;
    if (state.sort === name) {
      if (state.order === ORDER_DESC) {
        classChevron = <ArrowDropUpIcon className="tableUser__sort--arrow" />;
      } else {
        classChevron = <ArrowDropDownIcon className="tableUser__sort--arrow" />;
      }
    }
    return classChevron;
  };

  const openModal = user => () => {
    setModalIsOpen(true);
    setUserModal(user);
  };

  const openSearchBy = (type, setSearch, searchBy) => () => {
    setSearch(!searchBy);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const reloadUsers = ad => {
    dispatch({ type: 'RELOAD_USER_LIST', ad });
  };

  const confirm = async (txtModal, txtNotif, api) => {
    const confirmModal = window.confirm(
      `Veuillez confirmer votre action :\n${txtModal} de ${selectedUsers} ligne(s)`
    );
    if (confirmModal) {
      try {
        await api(getAdSelectedUsers);
        displayNotifictaion(txtNotif);
        unselectAll();
        dispatch({ type: 'RELOAD_USER_LIST' });
      } catch (e) {
        displayNotifictaion("Une erreur s'est produite", 'error');
      }
    }
  };

  const handleActivate = () => {
    confirm('Activation', 'Modification enregistrée', activateUsers);
  };

  const handleDeactivate = () => {
    confirm('Désactivation', 'Modification enregistrée', deactivateUsers);
  };

  const handleDelete = () => {
    confirm('Suppression', 'Suppression effectuée', deleteUsers);
  };

  return (
    <>
      <CreateEdit
        reloadUserList={reloadUsers}
        modalIsOpen={modalIsOpen}
        user={userModal}
        onRefreshUsers={handleCloseAndRefreshUsers}
        onClose={closeModal}
      />

      <Layout>
        <div className="listUser">
          <>
            {state.spinner && (
              <div className="loading-chanel-spinner">
                <div id="html-spinner" />
                <div className="chanel-spinner" />
              </div>
            )}
            <div className="listUser__userActions">
              <Button
                testId={'addUser'}
                className="button--white button--create"
                onClick={openModal()}
              >
                <AddUserIcon className="button--withIcon" />
                Creer un utilisateur
              </Button>
              {selectedUsers > 0 && (
                <div className="listUser__userActions__right">
                  <Button
                    testId={'activateUser'}
                    className="button--black"
                    onClick={handleActivate}
                  >
                    <ActivateIcon className="button--withIcon" />
                    Activer
                  </Button>
                  <div className="listUser__userActions__right__container">
                    <span className="listUser__userActions__right__border" />
                  </div>
                  <Button
                    testId={'deactivateUser'}
                    className="button--black"
                    onClick={handleDeactivate}
                  >
                    <DeactivateIcon className="button--withSmallIcon" />
                    Désactiver
                  </Button>
                  <div className="listUser__userActions__right__container">
                    <span className="listUser__userActions__right__border" />
                  </div>
                  <Button testId={'deleteUser'} className="button--red" onClick={handleDelete}>
                    <DeleteUserIcon className="button--withIcon" />
                    Supprimer
                  </Button>
                </div>
              )}
            </div>
            <table className="tableUser">
              <thead>
                <tr>
                  <th className="tableUser__header">
                    <CustomCheckbox
                      checked={allUsersAreSelected}
                      onClick={allUsersAreSelected ? unselectAll : selectAll}
                      partialyChecked={!allUsersAreSelected && selectedUsers > 0}
                      testId="selectAll"
                      menu={(onCloseMenu, menuRef) => (
                        <SelectUsersMenu
                          searchType={state.searchTypeMaster}
                          close={onCloseMenu}
                          menuRef={menuRef}
                          company={state.company}
                          totalCount={state.totalCount}
                        />
                      )}
                    />
                  </th>
                  <th className="tableUser__header">
                    <div className="tableUser__header__group">
                      <span onClick={openSearchBy('ad', setSearchByAdOpen, searchByAdOpen)}>
                        ad Chanel
                      </span>
                      <span onClick={handleSortClick(DB_AD)}>{getArrowIcon(DB_AD)}</span>
                    </div>
                    {searchByAdOpen && (
                      <SearchWindow
                        searchRef={searchRef}
                        setSpinner={() => dispatch({ type: 'CHANGE_SPINNER' })}
                        placeholder="Recherche par ad"
                        onSelectSearchElement={onSelectSearchElement}
                        onClose={openSearchBy('ad', setSearchByAdOpen, searchByAdOpen)}
                        searchType="ad"
                      />
                    )}
                  </th>
                  <th className="tableUser__header">
                    <div className="tableUser__header__group">
                      <span
                        onClick={openSearchBy(
                          'firstName',
                          setSearchByFirstNameOpen,
                          searchByFirstNameOpen
                        )}
                      >
                        Prenom
                      </span>
                      <span onClick={handleSortClick(DB_FIRSTNAME)}>
                        {getArrowIcon(DB_FIRSTNAME)}
                      </span>
                    </div>
                    {searchByFirstNameOpen && (
                      <SearchWindow
                        searchRef={searchRef}
                        setSpinner={() => dispatch({ type: 'CHANGE_SPINNER' })}
                        placeholder="Recherche par prénom"
                        onSelectSearchElement={onSelectSearchElement}
                        onClose={openSearchBy(
                          'firstName',
                          setSearchByFirstNameOpen,
                          searchByFirstNameOpen
                        )}
                        searchType="firstName"
                      />
                    )}
                  </th>
                  <th className="tableUser__header">
                    <div className="tableUser__header__group">
                      <span
                        onClick={openSearchBy(
                          'lastName',
                          setSearchByLastNameOpen,
                          searchByLastNameOpen
                        )}
                      >
                        Nom
                      </span>
                      <span onClick={handleSortClick(DB_LASTNAME)}>
                        {getArrowIcon(DB_LASTNAME)}
                      </span>
                    </div>
                    {searchByLastNameOpen && (
                      <SearchWindow
                        searchRef={searchRef}
                        setSpinner={() => dispatch({ type: 'CHANGE_SPINNER' })}
                        placeholder="Recherche par nom"
                        onSelectSearchElement={onSelectSearchElement}
                        onClose={openSearchBy(
                          'lastName',
                          setSearchByLastNameOpen,
                          searchByLastNameOpen
                        )}
                        searchType="lastName"
                      />
                    )}
                  </th>
                  <th className="tableUser__header" onClick={handleSortClick(DB_ROLE)}>
                    <div className="tableUser__header__group">
                      <span>Role</span>
                      {getArrowIcon(DB_ROLE)}
                    </div>
                  </th>
                  <th className="tableUser__header">
                    <div className="tableUser__header__group">
                      <span
                        onClick={openSearchBy(
                          'company',
                          setSearchByCompanyOpen,
                          searchByCompanyOpen
                        )}
                      >
                        Société
                      </span>
                      <span onClick={handleSortClick(DB_COMPANY)}>
                        {getArrowIcon(DB_COMPANY)}
                      </span>
                    </div>
                    {searchByCompanyOpen && (
                      <SearchWindow
                        searchRef={searchRef}
                        setSpinner={() => dispatch({ type: 'CHANGE_SPINNER' })}
                        placeholder="Recherche par société"
                        onSelectSearchElement={onSelectSearchElement}
                        onClose={openSearchBy(
                          'company',
                          setSearchByCompanyOpen,
                          searchByCompanyOpen
                        )}
                        searchType="company"
                      />
                    )}
                  </th>
                  <th className="tableUser__header">
                    <div className="tableUser__header__group">
                      <span
                        onClick={openSearchBy('mail', setSearchByEmailOpen, searchByEmailOpen)}
                      >
                        Email chanel
                      </span>
                      <span onClick={handleSortClick(DB_MAIL)}>{getArrowIcon(DB_MAIL)}</span>
                    </div>
                    {searchByEmailOpen && (
                      <SearchWindow
                        searchRef={searchRef}
                        setSpinner={() => dispatch({ type: 'CHANGE_SPINNER' })}
                        placeholder="Recherche par email"
                        onSelectSearchElement={onSelectSearchElement}
                        onClose={openSearchBy('mail', setSearchByEmailOpen, searchByEmailOpen)}
                        searchType="mail"
                      />
                    )}
                  </th>
                  <th className="tableUser__header" onClick={handleSortClick(DB_COUNTRY)}>
                    <div className="tableUser__header__group">
                      <span>Pays</span>
                      {getArrowIcon(DB_COUNTRY)}
                    </div>
                  </th>
                  <th className="tableUser__header" onClick={handleSortClick(DB_CITY)}>
                    <div className="tableUser__header__group">
                      <span>Ville</span>
                      {getArrowIcon(DB_CITY)}
                    </div>
                  </th>
                  <th className="tableUser__header" onClick={handleSortClick(DB_ACTIVATED)}>
                    <div className="tableUser__header__group">
                      <span>Activation</span>
                      {getArrowIcon(DB_ACTIVATED)}
                    </div>
                  </th>
                </tr>
              </thead>
              {!state.loading && users.length > 0 && (
                <tbody>
                  {users.map(user => (
                    <RowUser onEditUser={openModal(user)} user={user} key={user.ad} />
                  ))}
                </tbody>
              )}
            </table>
            {state.loading ? (
              <Loader />
            ) : (
              users.length === 0 && (
                <div className="tableUser__noUser">Aucun utilisateur trouvé</div>
              )
            )}
            <div className="tableUser__bottomSide">
              {state.filter && (
                <div onClick={onClearSearch} className={'tableUser__removeFilter'}>
                  <EraserIcon className="tableUser__removeFilter__icon" />
                  <span>ENLEVER LE FILTRE</span>
                </div>
              )}

              <div className="tableUser__informations">
                {state.totalCount !== 0 && (
                  <div data-testid="informations" className="tableUser__informations__count">
                    {`${state.totalCount} RÉSULTATS
                    ${
                      selectedUsers > 0
                        ? `| SÉLECTION: ${allPagesSelected ? state.totalCount : selectedUsers}`
                        : ''
                    }`}
                  </div>
                )}
                {state.pageCount > 1 && (
                  <ReactPaginate
                    previousLabel={<ArrowPreviousIcon className={'pagination__arrow'} />}
                    nextLabel={<ArrowNextIcon className={'pagination__arrow'} />}
                    breakLabel={<span>...</span>}
                    breakClassName={'ellipse clickable'}
                    pageCount={state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={6}
                    forcePage={state.currentPage}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'pagination--active'}
                  />
                )}
              </div>
            </div>
          </>
        </div>
      </Layout>
    </>
  );
};

TableUser.displayName = 'TableUser';
export default TableUser;
