import * as React from 'react';

const Loader = () => (
  <div className="loading-chanel-spinner">
    <div id="html-spinner" />
    <div className="chanel-spinner" />
  </div>
);

export default Loader;
