import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '../../app/textField/textField';
import { Dropdown } from '../../app/dropdown/dropdown';
import { Button } from '../../app/button/button';
import { ArrowRight } from '../../app/icons/arrowRightIcon';
import TickIcon from '../../app/icons/tickIcon';

const containsUppercase = (input) => {
  return input.error && /[A-Z]/.test(input.value);
};
export const UserTab = ({
  state,
  handleChange,
  onValidate,
  roles,
  languages,
  onSubmit,
  disabled,
  onRoleChange,
  user
}) => {
  return (
    <>
      <div className="createEdit__tabs__user">
        <div className="createEdit__tabs__user__group">
          <TextField
            value={state.ad.value}
            className="createEdit__tabs__user__ad"
            placeholder="Compte ad*"
            name="ad"
            onChange={handleChange}
            onBlur={onValidate}
            errorMessage={state.ad.error}
            testId="ad"
          />
        </div>
        <div className="createEdit__tabs__user__group">
          <TextField
            onChange={handleChange}
            className="createEdit__tabs__user__firstName createEdit__tabs__user__group__item"
            placeholder="Prénom"
            name="firstname"
            value={state.firstname.value}
            testId="firstName"
          />
          <TextField
            onChange={handleChange}
            className="createEdit__tabs__user__lastName createEdit__tabs__user__group__item"
            placeholder="Nom"
            name="lastname"
            value={state.lastname.value}
            testId="lastName"
          />
          <TextField
            onChange={handleChange}
            className="createEdit__tabs__user__mail createEdit__tabs__user__group__item"
            placeholder="Adresse email*"
            name="mail"
            value={state.mail.value}
            onBlur={onValidate}
            errorMessage={state.mail.error + (containsUppercase(state.mail) ? ' (majuscule) ' : '')}
            testId="mail"
          />
        </div>
        <div className="createEdit__tabs__user__group">
          <Dropdown
            value={state.role.value}
            itemList={roles}
            name="role"
            onChange={onRoleChange}
            onBlur={onValidate}
            className="createEdit__tabs__user__role createEdit__tabs__user__group__item"
            placeholder="Role*"
            errorMessage={state.role.error}
            testId="role"
          />
          <TextField
            onChange={handleChange}
            value={state.company.value}
            className="createEdit__tabs__user__company createEdit__tabs__user__group__item"
            placeholder="Société*"
            name="company"
            onBlur={onValidate}
            errorMessage={state.company.error}
            testId="company"
          />
          <TextField
            onChange={handleChange}
            className="createEdit__tabs__user__approver createEdit__tabs__user__group__item"
            placeholder="Email approbateur*"
            name="approver"
            value={state.approver.value}
            onBlur={onValidate}
            errorMessage={state.approver.error + (containsUppercase(state.approver) ? ' (majuscule) ' : '')}
            testId="approver"
          />
        </div>
        <div className="createEdit__tabs__user__group">
          <Dropdown
            value={state.language.value}
            itemList={languages}
            onChange={handleChange}
            className="createEdit__tabs__user__language createEdit__tabs__user__group__item"
            placeholder="Langue"
            name="language"
            testId="language"
          />
          <TextField
            onChange={handleChange}
            className="createEdit__tabs__user__country createEdit__tabs__user__group__item"
            placeholder="Pays"
            name="country"
            value={state.country.value}
            testId="country"
          />
          <TextField
            onChange={handleChange}
            className="createEdit__tabs__user__city createEdit__tabs__user__group__item"
            placeholder="Ville"
            name="city"
            value={state.city.value}
            testId="city"
          />
        </div>
      </div>
      <div className="createEdit__tabs__user__submit">
        <Button
          className={`button--black ${
            user
              ? 'createEdit__tabs__user__submit__saveButton'
              : 'createEdit__tabs__user__submit__nextButton'
          }`}
          onClick={onSubmit}
          disabled={disabled}
          testId={`${user ? 'save' : 'next'}`}
        >
          {user ? (
            <TickIcon className="button--withIcon" />
          ) : (
            <ArrowRight className="button--withIcon" />
          )}
          {user ? 'SAUVEGARDER' : 'SUIVANT'}
        </Button>
      </div>
    </>
  );
};
UserTab.propTypes = {
  state: PropTypes.object,
  handleChange: PropTypes.func,
  onValidate: PropTypes.func,
  roles: PropTypes.array,
  languages: PropTypes.array,
  onSubmit: PropTypes.func,
  user: PropTypes.any,
  disabled: PropTypes.bool,
  onRoleChange: PropTypes.func
};
