export const getSchema = () => {
  return {
    name: {
      value: '',
      error: '',
      required: true,
      validator: {
        regEx: /^.{1,12}$/,
        error: 'Le champ ne peut comporter que 12 caractères'
      }
    },
    url: {
      required: true,
      value: '',
      error: '',
      validator: {
        regEx: /^(https?:\/\/).*[.].*$/,
        error: "Cette URL n'est pas valide"
      }
    },
    warning: {
      value: false,
      error: ''
    },
    visible: {
      value: true,
      error: ''
    },
    sso: {
      value: false,
      error: ''
    },
    state: {
      required: true,
      value: 'EN LIGNE',
      error: ''
    },
    descriptionFr: {
      required: true,
      value: '',
      error: '',
      validator: {
        regEx: /^[\S\s*]{1,230}$/,
        error: 'Le champ ne peut comporter que 230 caractères'
      }
    },
    descriptionEn: {
      required: true,
      value: '',
      error: '',
      validator: {
        regEx: /^[\S\s*]{1,230}$/,
        error: 'Le champ ne peut comporter que 230 caractères'
      }
    },
    descriptionIt: {
      required: true,
      value: '',
      error: '',
      validator: {
        regEx: /^[\S\s*]{1,230}$/,
        error: 'Le champ ne peut comporter que 230 caractères'
      }
    },
    warningFr: {
      required: false,
      value: '',
      error: '',
      validator: {
        regEx: /^.{1,80}$/,
        error: 'Le champ ne peut comporter que 150 caractères'
      }
    },
    warningEn: {
      required: false,
      value: '',
      error: '',
      validator: {
        regEx: /^.{1,150}$/,
        error: 'Le champ ne peut comporter que 150 caractères'
      }
    },
    warningIt: {
      required: false,
      value: '',
      error: '',
      validator: {
        regEx: /^.{1,150}$/,
        error: 'Le champ ne peut comporter que 150 caractères'
      }
    }
  };
};
