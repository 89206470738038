import React from 'react';

export const CrossIcon = props => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <path d="M10.707 10.5l8.646-8.646a.5.5 0 00-.707-.707L10 9.793 1.354 1.147a.5.5 0 00-.707.707L9.293 10.5.647 19.146a.5.5 0 00.708.707l8.646-8.646 8.646 8.646a.498.498 0 00.708 0 .5.5 0 000-.707L10.709 10.5z" />
    </svg>
  );
};

CrossIcon.displayName = 'CrossIcon';
