import { config } from '../../adalConfig';

export function applicationsConfig() {
  return {
    api: {
      applications: {
        url: config.serverUrl + '/applications'
      }
    }
  };
}
