import React from 'react';

const UnknowPageIcon = props => (
  <svg width="200px" height="120px" viewBox="0 0 52.917 31.75" {...props}>
    <path
      d="M4.945 19.87h4.32V.76h4.582v18.807h2.638v5.267h-2.638v6.835H9.23v-6.835H0V5.418h4.945zM26.798 26.859c2.637 0 3.362-3.047 3.362-11.015 0-8.01-.725-10.969-3.362-10.969s-3.362 2.96-3.362 10.97c0 7.967.725 11.014 3.362 11.014m0-26.859c5.506 0 8.11 5.006 8.11 15.844 0 10.842-2.604 15.89-8.11 15.89-5.504 0-8.11-5.048-8.11-15.89C18.688 5.006 21.294 0 26.798 0M41.377 19.951h4.32V.841h4.582V19.65h2.638v5.266h-2.638v6.835h-4.616v-6.835h-9.23V5.5h4.944z"
      fill="gray"
    />
  </svg>
);

UnknowPageIcon.displayName = 'UnknowPageIcon';
export default UnknowPageIcon;
