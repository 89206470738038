import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../utils/formatDate';

export const ActivityTab = ({ creationDate, lastConnectionDate }) => {
  const getDisplayDate = date => {
    return date ? formatDate(date) : '-';
  };

  return (
    <div className="createEdit__tabs__activity">
      <h2 className="createEdit__tabs__activity__title">PORTAIL LINK</h2>
      <div className="createEdit__tabs__activity__item">
        <label className="createEdit__tabs__activity__label">Date de Création:</label>
        <span>{getDisplayDate(creationDate)}</span>
      </div>
      <div className="createEdit__tabs__activity__item">
        <label className="createEdit__tabs__activity__label">
          Date de dernière connexion:
        </label>
        <span>{getDisplayDate(lastConnectionDate)}</span>
      </div>
    </div>
  );
};

ActivityTab.propTypes = {
  creationDate: PropTypes.string,
  lastConnectionDate: PropTypes.string
};
