import React, { useEffect, useState } from 'react';
import { Layout } from '../../app/layout';
import { getApplications } from '../../services/applications/applicationsApi';
import Loader from '../loader';
import AddIcon from '../../app/icons/addIcon';
import CreateEdit from './createEdit';
import { InactiveIcon } from '../../app/icons/inactiveIcon';
import WarningIcon from '../../app/icons/warningIcon';

const Applications = () => {
  const [applications, setApplications] = useState([]);
  const [applicationModal, setApplicationModal] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isRefreshApplications, setIsRefreshApplications] = useState(true);

  useEffect(() => {
    if (isRefreshApplications) {
      const loadApplications = async () => {
        try {
          const data = await getApplications();
          setApplications(data);
          setLoading(false);
        } catch (e) {
          setApplications([]);
          setLoading(false);
        }
      };
      loadApplications();
    }
  }, [isRefreshApplications]);

  const handleCloseAndRefreshApplications = () => {
    closeModal();
    setIsRefreshApplications(true);
  };

  const openModal = app => () => {
    setApplicationModal(app);
    setModalIsOpen(true);
    setIsRefreshApplications(false);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const numberApplicationActive = () => applications.filter(app => app.visible).length;

  return (
    <>
      <CreateEdit
        modalIsOpen={modalIsOpen}
        application={applicationModal}
        onClose={closeModal}
        onRefreshApplications={handleCloseAndRefreshApplications}
      />
      <Layout>
        <div className="applications">
          {loading ? (
            <Loader />
          ) : (
            <>
              {applications.length === 0 ? (
                <div data-testid="no-app" className="noApplicationsFound">
                  Aucune application
                </div>
              ) : (
                <div className="applications__nb" data-testid="applications-nb">
                  {`${numberApplicationActive()} applications actives / ${
                    applications.length
                  } applications`}
                </div>
              )}
              <div className="applications__list">
                <div
                  className="applications__list__app"
                  data-testid="openModal"
                  onClick={openModal()}
                >
                  <AddIcon />
                </div>
                {applications.map((appli, index) => {
                  return (
                    <div
                      data-testid={`appli-${index}`}
                      className="applications__list__app"
                      key={index}
                      onClick={openModal(appli)}
                    >
                      {!appli.visible && (
                        <div
                          data-testid={`appli-inactive-${index}`}
                          className="applications__list__app--active"
                        >
                          <InactiveIcon />
                        </div>
                      )}
                      <span className="applications__list__app__name">
                        {appli.name}
                        {appli.warning && (
                          <WarningIcon
                            data-testid={`appli-warning-${index}`}
                            className="applications__list__app__warning"
                          />
                        )}
                      </span>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </Layout>
    </>
  );
};

Applications.displayName = 'Applications';
export default Applications;
