import { config } from '../../adalConfig';

export function rolesConfig() {
  return {
    api: {
      get: {
        url: config.serverUrl + '/roles'
      },
      getExport: {
        url: config.serverUrl + '/roles?export=true'
      }
    }
  };
}

export function getRoleViewsUrl(roleName) {
  return `${config.serverUrl}/roles/${roleName}`;
}
