import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../button/button';

const CancelButton = ({ labelCancel = 'Cancel', onClick }) => {
  return (
    <Button testid="cancel" type="button" className="button-cancel" onClick={onClick}>
      {labelCancel}
    </Button>
  );
};

CancelButton.propTypes = {
  labelCancel: PropTypes.string,
  onClick: PropTypes.func
};
CancelButton.displayName = 'CancelButton';
export default CancelButton;
