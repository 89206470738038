import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import { searchCompanies, searchUsers } from '../../../services/users/usersApi';
import { useDebouncedCallback } from '../../../utils/useDebounceCallback';
import { TextField } from '../../../app/textField/textField';

const SearchWindow = ({
  onSelectSearchElement,
  onClose,
  searchRef,
  placeholder,
  setSpinner,
  searchType
}) => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const [currentSearchPage, setCurrentSearchPage] = useState(0);
  const [maxSearchPage, setMaxSearchPage] = useState(0);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (searchKeyword.length > 2) {
      setSearchResult([]);
      const search = async () => debouncedCallback();
      search();
    }
  }, [searchKeyword]);

  const search = async page => {
    setSpinner();
    let result;
    if (searchType === 'company') {
      result = await searchCompanies(searchKeyword, page);
    } else {
      result = await searchUsers(searchType, searchKeyword, page);
    }
    setSpinner();
    if (!page) {
      setMaxSearchPage(result.totalCount < 10 ? 0 : Math.ceil(result.totalCount / 10));
    }
    setSearchResult([...searchResult, ...result.data]);
  };

  const [debouncedCallback] = useDebouncedCallback(search, 500);

  const nextSearch = () => {
    if (currentSearchPage === maxSearchPage) {
      return;
    }
    search(currentSearchPage + 1);
    setCurrentSearchPage(currentSearchPage + 1);
  };

  const onUserClick = result => () => {
    if (searchType !== 'company') {
      onSelectSearchElement(result.ad, searchType);
    } else {
      onSelectSearchElement(result, searchType);
    }
    onClose();
  };

  const resultColumnName = {
    mail: ['mail', 'firstName', 'lastName'],
    firstName: ['firstName', 'lastName', 'mail'],
    lastName: ['lastName', 'firstName', 'mail'],
    ad: ['ad', 'firstName', 'lastName']
  };

  const searchResultClassName = {
    mail: 'searchWindow-searchResults-item-withMail',
    firstName: 'searchWindow-searchResults-item-withoutMail',
    lastName: 'searchWindow-searchResults-item-withoutMail',
    ad: 'searchWindow-searchResults-item-withAd',
    company: 'head-searchWindow-searchResults-item-withCompany'
  };

  const onKeywordChange = ({ target: { value } }) => {
    setSearchKeyword(value);
  };

  return (
    <div ref={searchRef} className={'searchWindow'}>
      <TextField
        inputRef={inputRef}
        className={'searchWindow-searchBox'}
        type="text"
        placeholder={placeholder}
        onChange={onKeywordChange}
      />
      {searchResult.length > 0 ? (
        <div className={'searchWindow-searchResults'}>
          {searchResult.map((result, index) => {
            return (
              <div
                className={`searchWindow-searchResults-item ${searchResultClassName[searchType]}`}
                key={index}
                onClick={onUserClick(result)}
              >
                {searchType === 'company' ? (
                  <span>{result}</span>
                ) : (
                  <>
                    <span>{result[resultColumnName[searchType][0]]}</span>
                    <span>{result[resultColumnName[searchType][1]]}</span>
                    <span>{result[resultColumnName[searchType][2]]}</span>
                  </>
                )}
              </div>
            );
          })}
          <Waypoint onEnter={nextSearch} topOffset={'20%'} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

SearchWindow.propTypes = {
  onSelectSearchElement: PropTypes.func,
  onClose: PropTypes.func,
  searchRef: PropTypes.object,
  placeholder: PropTypes.string,
  setSpinner: PropTypes.func,
  searchType: PropTypes.string.isRequired
};
SearchWindow.displayName = 'SearchWindow';
export default SearchWindow;
