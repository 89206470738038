import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { CrossIcon } from '../../app/icons/crossIcon';
import { TextArea } from '../../app/textArea/textArea';
import Switch from 'react-switch';

const WarningSection = ({ state, handleChange, onValidate }) => {
  const [selectedWarningTab, setSelectedWarningTab] = useState('warningFr');
  const warningFields = ['warningFr', 'warningEn', 'warningIt'];

  const onSelectTab = (tab) => () => {
    setSelectedWarningTab(tab);
  };

  const isActiveClass = tabType =>
    tabType === selectedWarningTab ? 'modalApplication__tabs__item--active' : '';

  const handleChangeSwitch = checked => {
    handleChange({ target: { value: checked, name: 'warning' } });
    warningFields.forEach(name => {
      state[name].required = checked;
      if (!checked) {
        onValidate({ target: { name } });
      }
    });
  };

  return (
    <>
      <div className="modalApplication__warning modalApplication__space">
        <div className="modalApplication__tabs">
          {warningFields.map(name => (
            <Fragment key={name}>
              <span
                data-testid={`${name}Tab`}
                onClick={onSelectTab(name)}
                className={`modalApplication__tabs__item ${isActiveClass(name)}`}
              >
                <span className="modalApplication__tabs__label">{name.slice(-2)}</span>
              </span>
              <CrossIcon
                className={`modalApplication__tabs--error${state[name].error && '--show'}`}
              />
            </Fragment>
          ))}
        </div>
        <div className="modalApplication__warning__switch">
          <label className="modalApplication__switch">
            <span className="modalApplication__switch__text">Warning</span>
            <Switch
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={handleChangeSwitch}
              checked={state.warning.value}
              data-testid="switch-warning"
            />
          </label>
        </div>
      </div>
      <TextArea
        rows={'2'}
        name={selectedWarningTab}
        className="modalApplication__textArea"
        value={state[selectedWarningTab].value}
        onChange={handleChange}
        onBlur={onValidate}
        placeholder="Warning*"
        errorMessage={state[selectedWarningTab].error}
        testId={selectedWarningTab}
      />
    </>
  );
};

WarningSection.propTypes = {
  state: PropTypes.object,
  handleChange: PropTypes.func,
  onValidate: PropTypes.func
};

WarningSection.displayName = 'WarningTab';
export default WarningSection;
