export const DB_AD = 'ad';
export const DB_COMPANY = 'company';
export const DB_FIRSTNAME = 'firstName';
export const DB_LASTNAME = 'lastName';
export const DB_ROLE = 'role';
export const DB_CITY = 'city';
export const DB_COUNTRY = 'country';
export const DB_MAIL = 'mail';
export const DB_APPRO = 'approver';
export const DB_PHONE = 'phone';
export const DB_ACTIVATED = 'activated';
export const ORDER_ASC = 'asc';
export const ORDER_DESC = 'desc';
export const ORDER_DEFAULT = 'ad';
export const USER_SIZE = 10;
