import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Menu from '../components/menu/menu';
import NotificationSystem from 'react-notification-system';
import { NotificationContext } from '.';

export const Layout = ({ children }) => {
  const { notificationRef } = useContext(NotificationContext);

  return (
    <div className="app__content">
      <NotificationSystem ref={notificationRef} noAnimation={true} style={false} />
      <div className="app__content__left">
        <Menu />
      </div>
      <div className="app__content__right">{children}</div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.any
};
Layout.displayName = 'Layout';
