import { adalApiFetch } from '../../adalConfig';
import { rolesConfig, getRoleViewsUrl } from './rolesConfig';
import axios from 'axios';

export const getRoles = exportPage => {
  let uri = rolesConfig().api.get.url;
  if (exportPage) {
    uri = rolesConfig().api.getExport.url;
  }
  return adalApiFetch(axios, uri, {}).then(res => {
    return res.data;
  });
};

export const getRoleViews = roleName => {
  const uri = getRoleViewsUrl(roleName);
  return adalApiFetch(axios, uri, {}).then(res => {
    return res.data;
  });
};

export const updateRoleViews = (roleName, viewCodes) => {
  const uri = getRoleViewsUrl(roleName);
  return adalApiFetch(axios, uri, {
    method: 'PUT',
    data: viewCodes
  }).then(res => {
    return res;
  });
};
