import React, { useCallback, useState, useContext } from 'react';
import { Layout } from '../../app/layout';
import UploadIcon from '../../app/icons/uploadIcon';
import { useDropzone } from 'react-dropzone';
import { importUsers } from '../../services/users/usersApi';
import FormButtons from '../../app/formButtons/formButtons';
import { NotificationContext } from '../../app';
import { Title } from '../../app/title/title';

const ImportUser = () => {
  const [file, setFile] = useState();
  const { displayNotifictaion } = useContext(NotificationContext);
  const [spinner, setSpinner] = useState(false);

  const onDrop = useCallback(file => {
    const fileExtension = file[0].name.split('.').pop();
    if (fileExtension === 'xls' || fileExtension === 'xlsx') {
      setFile(file[0]);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: ['.xls', '.xlsx'] });

  const onCancel = useCallback(() => {
    setFile(null);
  }, []);

  const onSubmit = async e => {
    e.preventDefault();
    if (file) {
      let notificationMessage = '';
      let autoDismiss;
      let notificationLevel = 'success';
      try {
        setSpinner(true);
        const response = await importUsers(file);
        setSpinner(false);
        if (response.status === 201) {
          if (response.data.state === 'UPDATE') {
            notificationMessage = 'Mis à jour avec succès ';
          } else if (response.data.state === 'SAVE_AND_UPDATE') {
            notificationMessage = 'Importé et mis à jour avec succès ';
          } else {
            notificationMessage = 'Importé avec succès';
          }
        }
        if (response.status === 204) {
          notificationMessage = 'Le fichier est vide';
        }
        if (response.status === 206) {
          const lines = response.data.lines.join(', ');
          if (response.data.state === 'UPDATE') {
            notificationMessage = `Mis à jour avec succès à l'exception des lignes: ${lines}`;
          } else if (response.data.state === 'SAVE_AND_UPDATE') {
            notificationMessage = `Importé et mis à jour avec succès à l'exception des lignes: ${lines}`;
          } else {
            notificationMessage = `Importé avec succès à l'exception des lignes: ${lines}`;
          }
          autoDismiss = 0;
        }
      } catch (error) {
        setSpinner(false);
        notificationLevel = 'error';
        if (error.response.status === 400) {
          notificationMessage = "Fichier non importé l'entête est invalide";
        }
        if (error.response.status === 409) {
          notificationMessage =
            "Fichier non importé l'ensemble des utilisateurs est mal renseigné";
          autoDismiss = 0;
        }
        if (error.response.status === 500) {
          notificationMessage =
            "Une erreur s'est produite lors de l'import veuillez contacter le support";
        }
      }
      displayNotifictaion(notificationMessage, notificationLevel, autoDismiss);
      setFile(null);
    }
  };

  return (
    <Layout>
      <div className="import-user">
        <Title title="Import" subTitle="Import de masse d’une liste d’utilisateurs" />
        <form onSubmit={onSubmit}>
          <div {...getRootProps()} className="import-user-upload">
            <UploadIcon width="150px" height="90px" />
            {file ? <span>{file.name}</span> : ''}
            <input {...getInputProps()} />
            <button type="button" className={'import-user-uploadButton'}>
              Import excel
            </button>
          </div>
          <div className={'import-user-buttons'}>
            {spinner && (
              <div className="loading-chanel-spinner">
                <div id="html-spinner" />
                <div className="chanel-spinner" />
              </div>
            )}
            <FormButtons labelSubmit="Importer" labelCancel="annuler" onCancel={onCancel} />
          </div>
        </form>
      </div>
    </Layout>
  );
};

ImportUser.displayName = 'ImportUser';
export default ImportUser;
