import React from 'react';
import PropTypes from 'prop-types';

const ArrowDropDownIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 20 20">
    <path d="M0 6a.5.5 0 01.853-.354l8.646 8.646 8.646-8.646a.5.5 0 01.707.707l-9 9a.5.5 0 01-.707 0l-9-9a.498.498 0 01-.146-.354z" />
  </svg>
);

ArrowDropDownIcon.propTypes = {
  className: PropTypes.string
};
ArrowDropDownIcon.displayName = 'ArrowDropDownIcon';
export default ArrowDropDownIcon;
