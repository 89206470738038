export const LOAD = 'LOAD';
export const SELECT = 'SELECT';
export const SELECT_ALL = 'SELECT_ALL';
export const UNSELECT_ALL = 'UNSELECT_ALL';
export const SELECT_ALL_PAGES = 'SELECT_ALL_PAGES';
export const CHANGE_PAGE = 'CHANGE_PAGE';

export const initialState = {
  users: [],
  selectedUsers: [],
  selectedAll: false,
  selectAllPages: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD: {
      return { ...state, users: [...action.users] };
    }

    case SELECT_ALL: {
      return {
        ...state,
        selectedUsers: state.users.map(user => user.ad),
        selectedAll: true,
        selectAllPages: false
      };
    }

    case UNSELECT_ALL: {
      return {
        ...state,
        selectedUsers: [],
        selectedAll: false,
        selectAllPages: false
      };
    }

    case SELECT_ALL_PAGES: {
      return {
        ...state,
        selectedUsers: action.data ? action.data : state.users.map(user => user.ad),
        selectedAll: true,
        selectAllPages: true
      };
    }

    case CHANGE_PAGE: {
      return { ...state, selectedUsers: [], selectedAll: false, selectAllPages: false };
    }

    case SELECT: {
      if (state.selectedUsers.includes(action.ad)) {
        return {
          ...state,
          selectedUsers: state.selectedUsers.filter(t => t !== action.ad),
          selectedAll: false,
          selectAllPages: false
        };
      } else {
        if (state.selectedUsers.length + 1 === state.users.length) {
          return {
            ...state,
            selectedUsers: [...state.selectedUsers, action.ad],
            selectedAll: true,
            selectAllPages: false
          };
        }
        return {
          ...state,
          selectedUsers: [...state.selectedUsers, action.ad],
          selectAllPages: false
        };
      }
    }

    default:
      return state;
  }
}
