import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { CrossIcon } from '../../app/icons/crossIcon';
import { TextArea } from '../../app/textArea/textArea';

const DescriptionSection = ({ state, handleChange, onValidate }) => {
  const [selectedDescriptionTab, setSelectedDescriptionTab] = useState('descriptionFr');
  const descriptionFields = ['descriptionFr', 'descriptionEn', 'descriptionIt'];

  const onSelectTab = (tab) => () => {
    setSelectedDescriptionTab(tab);
  };

  const isActiveClass = tabType =>
    tabType === selectedDescriptionTab ? 'modalApplication__tabs__item--active' : '';

  return (
    <>
      <div className="modalApplication__tabs modalApplication__space">
        {descriptionFields.map(name => (
          <Fragment key={name}>
            <span
              data-testid={`${name}Tab`}
              onClick={onSelectTab(name)}
              className={`modalApplication__tabs__item ${isActiveClass(name)}`}
            >
              <span className="modalApplication__tabs__label">{name.slice(-2)}</span>
            </span>
            <CrossIcon
              className={`modalApplication__tabs--error${state[name].error && '--show'}`}
            />
          </Fragment>
        ))}
      </div>
      <TextArea
        rows={'2'}
        name={selectedDescriptionTab}
        className="modalApplication__textArea"
        value={state[selectedDescriptionTab].value}
        onChange={handleChange}
        onBlur={onValidate}
        placeholder="Description*"
        errorMessage={state[selectedDescriptionTab].error}
        testId={selectedDescriptionTab}
      />
    </>
  );
};

DescriptionSection.propTypes = {
  state: PropTypes.object,
  handleChange: PropTypes.func,
  onValidate: PropTypes.func
};

DescriptionSection.displayName = 'DescriptionTab';
export default DescriptionSection;
