import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../app/languageContext';
import WarningIcon from '../../app/icons/warningIcon';
import { useMemo } from 'react';

const Hive = () => {
  const [tool, setTool] = useState(false);
  const [toolWarning, setToolWarning] = useState(false);
  const [toolText, setToolText] = useState('');
  const [toolTextWarning, setToolTextWarning] = useState('');
  const [hovered, setHovered] = useState(false);
  const [codeHovered, setCodeHovered] = useState('');
  const [svgHeight, setSvgHeight] = useState(0);
  
  const user = JSON.parse(sessionStorage.getItem('current'));
  let x = 0;
  let y = 100;
  const scale = 1;
  const [lang] = useContext(LanguageContext);

  const handleClickApp = (path, code) => () => {
    if (code !== 'ADM') {
      const win = window.open(path);
      win.focus();
    } else {
      const win = window.open('#/list-user', '_self');
      win.focus();
    }
  };

  const calculpoint = index => {
    if (index === 1 || index === 3 || index === 4 || index === 6) {
      x = x + 113 * scale;
      y = y - 65 * scale;
    }

    if (index === 2 || index === 5) {
      y = y + 130 * scale;
    }

    if (index === 7) {
      x = 0;
      y = y + 130 * scale;
    }

    if (index > 9 && (index + 3) % 5 === 0) {
      x = 0;
      y = y + 65 * scale;
    }

    if (
      index > 7 &&
      ((index + 2) % 5 === 0 || (index + 1) % 5 === 0 || (index + 4) % 5 === 0)
    ) {
      x = x + 226 * scale;
    }

    if (index > 7 && index % 5 === 0) {
      x = x - 339 * scale;
      y = y + 65 * scale;
    }

    return `${x} ${y}`;
  };

  const tooltip = document.getElementById('tooltip');

  window.onmousemove = function(e) {
    if (tooltip) {
      const x = e.clientX;
      const y = e.clientY;
      tooltip.style.top = y - 50 + 'px';
      tooltip.style.left = x + 20 + 'px';
    }
  };

  const handleMouseOver = (text, code, textWarning) => () => {
    setHovered(true);
    setCodeHovered(code);
    if (text) {
      setTool(true);
      setToolText(text);
    }
    if (textWarning) {
      setToolWarning(true);
      setToolTextWarning(textWarning);
    }
  };

  const handleMouseleave = () => {
    setHovered(false);
    setTool(false);
    setToolWarning(false);
    setCodeHovered('');
  };

  const setColorClass = code => {
    if (hovered && code === codeHovered) {
      return user.applications.find(app => app.code === code).state === 'HORS LIGNE'
        ? 'colorKo'
        : user.applications.find(app => app.code === code).sso
          ? 'colorSso'
          : 'colorNoSso';
    }
  };

  const getDescriptionValue = (app, name) =>
    app[name].find(desc => desc.language === lang).text;

  const toolTipCss = {
    display: tool ? 'block' : 'none',
    whiteSpace: 'break-spaces'
  };
  useMemo(()=>{
    console.log("useMemo")
    let numberApplications = user.applications ? user.applications.length : 0;
    let svgHeightCalculated = numberApplications != 0 ? (150 * Math.ceil(numberApplications / 5)) + 160 : 0;
    setSvgHeight(svgHeightCalculated);
  },[])
  

  return (
    
    <>
      <div className="hive">
        <div className="honeycomb honeycomb__container">
          <svg className="honeycomb" width="610" viewBox={`0 0 610 ${svgHeight}`}>
            {user.applications.map((app, index) => {
              return (
                <g
                  key={index}
                  className={`honeycomb__hexa ${setColorClass(app.code)}`}
                  transform={`translate(${calculpoint(index)}) scale(${scale})`}
                  onClick={handleClickApp(app.url, app.code)}
                  onMouseOver={handleMouseOver(
                    app.applicationDescriptions &&
                      getDescriptionValue(app, 'applicationDescriptions'),
                    app.code,
                    app.warning && getDescriptionValue(app, 'applicationWarnings')
                  )}
                  onMouseLeave={handleMouseleave}
                >
                  <path d="M150,75 112.5,140 37.5,140 0,75 37.5,10 112.5,10Z" />
                  <foreignObject width="150" height="150">
                    <div className="honeycomb__hexa__foreign">
                      <p style={{fontSize: "80%"}}>{app.name}</p>
                      {app.warning && (
                        <WarningIcon className="honeycomb__hexa__foreign__warning" />
                      )}
                    </div>
                  </foreignObject>
                </g>
              );
            })}
            <defs>
              <svg width="100px" height="100px">
                <linearGradient
                  id="colorSso"
                  gradientUnits="userSpaceOnUse"
                  fy="90%"
                  x1="100%"
                  y1="0%"
                  x2="20%"
                  y2="56%"
                >
                  <stop offset="0.3" className="colorSsoStart" />
                  <stop offset="1" className="colorSsoEnd" />
                </linearGradient>
                <linearGradient
                  id="colorKo"
                  gradientUnits="userSpaceOnUse"
                  fy="90%"
                  x1="100%"
                  y1="0%"
                  x2="20%"
                  y2="100%"
                >
                  <stop offset="0.2" className="colorKoStart" />
                  <stop offset="1" className="colorKoEnd" />
                </linearGradient>
                <linearGradient
                  id="colorNoSso"
                  gradientUnits="userSpaceOnUse"
                  fy="90%"
                  x1="100%"
                  y1="0%"
                  x2="20%"
                  y2="100%"
                >
                  <stop offset="0.2" className="colorNoSsoStart" />
                  <stop offset="1" className="colorNoSsoEnd" />
                </linearGradient>
              </svg>
            </defs>
          </svg>
        </div>
        <div className="tooltip">
          <span style={toolTipCss} id="tooltip">
            {toolText}
          </span>
        </div>
        <div
          style={{ display: toolWarning ? 'block' : 'none' }}
          className="notification-home notification-home-warning"
        >
          <span className="notification-message">{toolTextWarning}</span>
        </div>
      </div>
    </>
  );
};

Hive.displayName = 'Hive';
export default Hive;
