import { useReducer } from 'react';
import { ORDER_DEFAULT, ORDER_ASC } from '../../utils/userConstant';

export const useListUserReducer = (changePage, load) =>
  useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SORT':
          return {
            ...state,
            sort: action.sort,
            order: action.order,
            currentPage: action.currentPage
          };

        case 'FILTER_LIST': {
          let typeMaster;
          let company;
          console.log(action.searchValue);
          if (action.searchType !== 'company') {
            typeMaster = 'user';
            company = action.searchValue;
          } else {
            typeMaster = action.searchType;
          }
          return {
            ...state,
            currentPage: action.currentPage,
            searchTypeMaster: typeMaster,
            searchValue: action.searchValue,
            filter: true,
            spinner: false,
            company
          };
        }

        case 'CLEAR_SEARCH':
          return {
            ...state,
            currentPage: 0,
            order: ORDER_ASC,
            sort: ORDER_DEFAULT,
            searchTypeMaster: '',
            searchValue: '',
            filter: false,
            company: null
          };

        case 'LOAD_USERS_SUCESSFUL':
          load(action.data.users);

          return {
            ...state,
            pageCount: action.data.nbPage,
            totalCount: action.data.totalCount,
            loading: false,
            filter: !!action.filter,
            spinner: action.spinner,
            company: action.company
          };

        case 'LOAD_USERS_FAILURE':
          load([]);
          return { ...state, loading: false, filter: false, spinner: false };

        case 'CHANGE_PAGE':
          changePage();
          return { ...state, currentPage: action.page };

        case 'CHANGE_SPINNER':
          return { ...state, spinner: !state.spinner };

        case 'RELOAD_USER_LIST':
          if (action.ad && state.searchValue && state.searchTypeMaster === 'user') {
            return { ...state, searchValue: action.ad };
          } else {
            return { ...state, reloadUserList: true };
          }

        case 'RESET_RELOAD_USER_LIST':
          return { ...state, reloadUserList: false };

        default:
          return state;
      }
    },
    {
      searchValue: '',
      searchTypeMaster: null,
      sort: ORDER_DEFAULT,
      order: ORDER_ASC,
      currentPage: 0,
      spinner: false,
      loading: false,
      pageCount: 0,
      totalCount: 0,
      filter: false,
      reloadUserList: false,
      company: null
    }
  );
