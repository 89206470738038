import React, { useContext, useEffect, useState } from 'react';
import { NotificationContext } from '../../app';
import { TextField } from '../../app/textField/textField';
import {
  createApplication,
  deleteApplication,
  updateApplication
} from '../../services/applications/applicationsApi';
import PropTypes from 'prop-types';
import { useForm } from '../../utils/useForm';
import { CrossIcon } from '../../app/icons/crossIcon';
import ReactModal from 'react-modal';
import { Button } from '../../app/button/button';
import TickIcon from '../../app/icons/tickIcon';
import Switch from 'react-switch';
import { Dropdown } from '../../app/dropdown/dropdown';
import DescriptionSection from './descriptionSection';
import WarningSection from './warningSection';
import { getSchema } from './schema';

const CreateEdit = ({ modalIsOpen, application, onClose, onRefreshApplications }) => {
  const { displayNotifictaion } = useContext(NotificationContext);
  const [copySchema, setCopySchema] = useState({});
  const [deleteConf, setDeleteConf] = useState(false);
  const stateSchema = getSchema();

  const getDescriptionValue = (name, lang) => {
    const element = application[name].find(desc => desc.language === lang);
    return element ? element.text : '';
  };

  const statesApp = ['EN LIGNE', 'HORS LIGNE'];

  const closeModal = () => {
    if (!isPristine()) {
      const confirmModal = window.confirm(
        'Êtes-vous sûr de vouloir quitter sans sauvegarder ?'
      );
      if (confirmModal) {
        closeAction();
      }
    } else {
      closeAction();
    }
  };

  const closeAction = () => {
    reset();
    onClose();
  };

  const submitApplication = async () => {
    const applicationDescriptions = [
      {
        language: 'fr-FR',
        text: state.descriptionFr.value
      },
      {
        language: 'en-US',
        text: state.descriptionEn.value
      },
      {
        language: 'it-IT',
        text: state.descriptionIt.value
      }
    ];
    const applicationWarnings = [
      {
        language: 'fr-FR',
        text: state.warningFr.value
      },
      {
        language: 'en-US',
        text: state.warningEn.value
      },
      {
        language: 'it-IT',
        text: state.warningIt.value
      }
    ];
    try {
      if (application) {
        await updateApplication(
          state.name.value,
          state.url.value,
          applicationDescriptions,
          applicationWarnings,
          application.code,
          state.warning.value,
          state.visible.value,
          state.sso.value,
          state.state.value
        );
      } else {
        await createApplication(
          state.name.value,
          state.url.value,
          applicationDescriptions,
          applicationWarnings,
          state.warning.value,
          state.visible.value,
          state.sso.value,
          state.state.value
        );
      }
      displayNotifictaion('Application sauvegardée');
      reset();
      onRefreshApplications();
    } catch (e) {
      displayNotifictaion("Une erreur s'est produite", 'error');
      if (e.response.data.code === 'DUPLICATE_KEY') {
        setErrorField('Ce nom est déjà utilisé', 'name');
      }
    }
  };

  const deleteConfirmation = () => {
    const confirmModal = window.confirm(
      `Êtes-vous sûr de vouloir supprimer l'application suivante ?\n${application.name}`
    );
    if (confirmModal) {
      setDeleteConf(true);
    }
  };

  useEffect(() => {
    if (deleteConf) {
      deleteApp();
    }
  }, [deleteConf]);

  const deleteApp = async () => {
    try {
      await deleteApplication(application.code);
      reset();
      setDeleteConf(false);
      onRefreshApplications();
      displayNotifictaion('Application supprimée');
    } catch (e) {
      displayNotifictaion("Une erreur s'est produite", 'error');
      setDeleteConf(false);
    }
  };

  const reset = () => {
    handleReset();
  };

  const {
    state,
    handleChange,
    onValidate,
    handleSubmit,
    setErrorField,
    handleReset,
    setState
  } = useForm(stateSchema, submitApplication);

  useEffect(() => {
    const copySchema = { ...stateSchema };
    if (application && modalIsOpen) {
      copySchema.name.value = application.name;
      copySchema.url.value = application.url;
      copySchema.warning.value = application.warning;
      copySchema.visible.value = application.visible;
      copySchema.sso.value = application.sso;
      copySchema.state.value = application.state;
      copySchema.descriptionEn.value = getDescriptionValue('applicationDescriptions', 'en-US');
      copySchema.descriptionFr.value = getDescriptionValue('applicationDescriptions', 'fr-FR');
      copySchema.descriptionIt.value = getDescriptionValue('applicationDescriptions', 'it-IT');
      copySchema.warningEn.value = getDescriptionValue('applicationWarnings', 'en-US');
      copySchema.warningFr.value = getDescriptionValue('applicationWarnings', 'fr-FR');
      copySchema.warningIt.value = getDescriptionValue('applicationWarnings', 'it-IT');
      setState(copySchema);
    }
    setCopySchema(copySchema);
  }, [application, modalIsOpen]);

  const submit = e => {
    e.preventDefault();
    handleSubmit();
  };

  const isPristine = () => {
    for (const field in copySchema) {
      if (copySchema[field].value !== state[field].value) {
        return false;
      }
    }
    return true;
  };

  const handleChangeSwitch = name => checked => {
    handleChange({ target: { value: checked, name: name } });
  };

  return (
    <ReactModal
      className="modalApplication"
      overlayClassName="modalOverlay"
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={true}
    >
      <div className="modalApplication__header" data-testid="modalApplication">
        <h1 className="modalApplication__title">Applications</h1>
        <CrossIcon
          className="modalApplication__close"
          data-testid="closeModal"
          onClick={closeModal}
        />
      </div>
      <form onSubmit={submit}>
        <div className="modalApplication__fields">
          <div className="modalApplication__leftSide">
            <TextField
              testId="name"
              name="name"
              value={state.name.value}
              onChange={handleChange}
              onBlur={onValidate}
              errorMessage={state.name.error}
              placeholder="Nom*"
              className="modalApplication__textField__small"
            />
            <TextField
              name="url"
              value={state.url.value}
              onChange={handleChange}
              onBlur={onValidate}
              placeholder="Url*"
              errorMessage={state.url.error}
              testId="url"
              className="modalApplication__textField__large"
            />
            <DescriptionSection
              state={state}
              handleChange={handleChange}
              onValidate={onValidate}
            />
            <WarningSection
              state={state}
              handleChange={handleChange}
              onValidate={onValidate}
            />
          </div>
          <div className="modalApplication__rightSide">
            <label className="modalApplication__switch">
              <span className="modalApplication__switch__text">Visibilité</span>
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={handleChangeSwitch('visible')}
                checked={state.visible.value}
                data-testid="switch-visible"
              />
            </label>
            <label className="modalApplication__switch">
              <span className="modalApplication__switch__text">Sso</span>
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={handleChangeSwitch('sso')}
                checked={state.sso.value}
                data-testid="switch-sso"
              />
            </label>
            <Dropdown
              value={state.state.value}
              itemList={statesApp}
              onChange={handleChange}
              onBlur={onValidate}
              className="modalApplication__dropDown"
              placeholder="Statut*"
              name="state"
              errorMessage={state.state.error}
              testId="state"
            />
          </div>
        </div>
        <div className="modalApplication__submit">
          {application && (
            <Button
              className="modalApplication__submit__button button--red"
              testId="delete"
              type="button"
              onClick={deleteConfirmation}
            >
              <CrossIcon className="button--withIcon" />
              Supprimer
            </Button>
          )}
          <Button
            type="submit"
            className="modalApplication__submit__button button--black"
            disabled={isPristine()}
            testId="save"
          >
            <TickIcon className="button--withIcon" />
            Sauvegarder
          </Button>
        </div>
      </form>
    </ReactModal>
  );
};

CreateEdit.propTypes = {
  modalIsOpen: PropTypes.bool,
  application: PropTypes.object,
  onClose: PropTypes.func,
  onRefreshApplications: PropTypes.func
};

CreateEdit.displayName = 'CreateEdit';
export default CreateEdit;
