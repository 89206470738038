import React, { useCallback, useContext } from 'react';
import { authContext } from '../../adalConfig';
import LogoutIcon from '../../app/icons/logoutIcon';
import { LanguageContext } from '../../app/languageContext';
import LogoIcon from '../../app/icons/logoIcon';
import { Dropdown } from '../../app/dropdown/dropdown';
import { Link } from 'react-router-dom';
import { updateLanguage } from '../../services/users/usersApi';
import LogoIconPartner from '../../app/icons/logoIconPartner';

export const Header = () => {
  const currentUser = JSON.parse(sessionStorage.getItem('current'));
  const [lang, setLang] = useContext(LanguageContext);

  const allLang = [
    { code: 'en-US', name: 'English' },
    { code: 'fr-FR', name: 'Français' },
    { code: 'it-IT', name: 'Italian' }
  ];

  const handleClickLogout = useCallback(() => {
    authContext.logOut();
  }, []);

  const onSelectLang = async ({ target: { value } }) => {
    try {
      await updateLanguage(value);
      sessionStorage.setItem('current', JSON.stringify({ ...currentUser, language: value }));
      setLang(value);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <header className="header">
      <div>
        <Link className="header__link" to="/#">
          {currentUser.role.code === 'PARTENAIRE' ? <LogoIconPartner /> : <LogoIcon />}
        </Link>
      </div>
      <div className="header__right">
        <span data-testid="mail" className="header__mail">
          {currentUser && currentUser.mail}
        </span>
        <Dropdown
          value={lang}
          itemList={allLang}
          onChange={onSelectLang}
          name="lang"
          className="header__lang"
          testId="language"
        />
        <LogoutIcon className="header__logout" onClick={handleClickLogout} />
      </div>
    </header>
  );
};

Header.displayName = 'Header';
export default Header;
