import React from 'react';

const WarningIcon = props => (
  <svg viewBox="0 0 376.16 376.16" width="1em" height="1em" {...props}>
    <defs>
      <style>
        {
          '.Warning_Icon_svg__cls-1{fill:#ff9406}.Warning_Icon_svg__cls-2{fill:#f7d1a9;stroke:#f7d1a9;stroke-miterlimit:10}'
        }
      </style>
    </defs>
    <circle className="Warning_Icon_svg__cls-1" cx={188.08} cy={188.08} r={188.08} />
    <circle className="Warning_Icon_svg__cls-2" cx={188.08} cy={188.08} r={158.5} />
    <circle className="Warning_Icon_svg__cls-1" cx={188.08} cy={188.08} r={149.94} />
    <path
      className="Warning_Icon_svg__cls-2"
      d="M663.23 265.09c-9.09-2.38-18.57-3.3-27.87-4.89-.76-.14-1.51-.33-2.26-.49h-18.74c-5.59.77-11.19 1.41-16.76 2.32-58.49 9.48-108.16 57-120.24 115.19-1.4 6.76-2.06 13.67-3.07 20.51-.13.86-.33 1.7-.5 2.55V419c.75 5.49 1.36 11 2.28 16.46 9.74 58.29 55 106.54 112.69 119.92 7.66 1.78 15.56 2.49 23.35 3.7.76.12 1.5.33 2.25.49h18.74c5.49-.76 11-1.38 16.46-2.28C706 548 753.65 504.72 768.29 449.15c2.37-9 3.28-18.37 4.87-27.58.15-.85.34-1.7.51-2.55v-18.74c-.75-5.49-1.38-11-2.28-16.47-9.25-56.36-52.65-104.16-108.16-118.72zm-20.91 238H605.2V391h37.12zm0-149.86h-37.09v-37.08h37.12z"
      transform="translate(-435.65 -221.57)"
    />
  </svg>
);

WarningIcon.displayName = 'TickIcon';
export default WarningIcon;
