const months = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Decembre'
];

export function formatDate(str) {
  const date = new Date(str);
  return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
}
