import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../button/button';

const SubmitButton = ({ labelSubmit, disabled }) => {
  return (
    <Button testid="submit" type="submit" className="button--black" disabled={disabled}>
      {labelSubmit}
    </Button>
  );
};

SubmitButton.propTypes = {
  labelSubmit: PropTypes.string,
  disabled: PropTypes.bool
};
SubmitButton.displayName = 'SubmitButton';
export default SubmitButton;
