import React from 'react';

export const InactiveIcon = props => {
  return (
    <svg viewBox="0 0 107.27 107.27" width="1em" height="1em" {...props}>
      <defs>
        <style>{'.Visual_Indicator_2_svg__cls-1{fill:#ef504d}'}</style>
      </defs>
      <g id="Visual_Indicator_2_svg__Layer_2" data-name="Layer 2">
        <g id="Visual_Indicator_2_svg__Layer_1-2" data-name="Layer 1">
          <circle className="Visual_Indicator_2_svg__cls-1" cx={53.64} cy={53.64} r={53.64} />
          <circle cx={53.64} cy={53.64} r={45.2} fill="#fb9297" />
          <circle className="Visual_Indicator_2_svg__cls-1" cx={53.64} cy={53.64} r={17.05} />
        </g>
      </g>
    </svg>
  );
};

InactiveIcon.displayName = 'InactiveIcon';
