import React, { useState } from 'react';
import PropTypes from 'prop-types';

const LanguageContext = React.createContext({});

const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState(JSON.parse(sessionStorage.getItem('current')).language ? JSON.parse(sessionStorage.getItem('current')).language : 'en-US');
  return (
    <LanguageContext.Provider value={[lang, setLang]}>{children}</LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

LanguageProvider.displayName = 'LanguageProvider';

export { LanguageContext, LanguageProvider };
