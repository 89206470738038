import React from 'react';
import PropTypes from 'prop-types';
import palette from '../../assets/scss/_palette.scss';

const ArrowNextIcon = ({ width = '24px', height = '24px', className }) => (
  <svg
    width={width}
    height={height}
    fill={palette.greyTxt}
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

ArrowNextIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string
};
ArrowNextIcon.displayName = 'ArrowNextIcon';
export default ArrowNextIcon;
