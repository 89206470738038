import React from 'react';
import PropTypes from 'prop-types';

export const Title = ({ title, subTitle }) => {
  return (
    <div className="title">
      <h1>{title}</h1>
      <h2>{subTitle}</h2>
    </div>
  );
};

Title.displayName = 'Title';
Title.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string
};
