import React from 'react';

export const AngleDownIcon = props => {
  return (
    <svg width="7px" height="5px" viewBox="0 0 7 6" {...props}>
      <path d="M3.5 5.09L7 0H0z" fill="#000" fillRule="evenodd" />
    </svg>
  );
};

AngleDownIcon.displayName = 'AngleDownIcon';
export default AngleDownIcon;
