import React from 'react';

export const DeactivateIcon = props => {
  return (
    <svg viewBox="0 0 9 8.99" width="1em" height="1em" {...props}>
      <g data-name="Layer 2">
        <path
          d="M4.5 1A3.48 3.48 0 018 4.49 3.45 3.45 0 017 7a3.5 3.5 0 01-2.5 1A3.46 3.46 0 012 7a3.48 3.48 0 010-5 3.47 3.47 0 012.5-1zm0-1A4.5 4.5 0 109 4.49 4.5 4.5 0 004.5 0z"
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
};

DeactivateIcon.displayName = 'DeactivateIcon';
