import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';

const Menu = ({ location: { pathname } }) => {
  const [currentPath, setCurrentPath] = useState(pathname);

  useEffect(() => {
    setCurrentPath(pathname);
  }, [pathname]);

  return (
    <>
      {currentPath !== '/' && (
        <div data-testid="menu" className="menu">
          <ul className="nav-links">
            <li>
              <NavLink className="menu__link" activeClassName="menu__active" to="/list-user">
                Utilisateurs
              </NavLink>
            </li>
            <li>
              <NavLink
                className="menu__link"
                activeClassName="menu__active"
                to="/applications"
              >
                Applications
              </NavLink>
            </li>
            <li>
              <NavLink className="menu__link" activeClassName="menu__active" to="/import-user">
                Import utilisateur
              </NavLink>
            </li>
            <li>
              <NavLink className="menu__link" activeClassName="menu__active" to="/export-user">
                Export utilisateur
              </NavLink>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

Menu.propTypes = {
  location: PropTypes.object
};
export default withRouter(Menu);
