import { adalApiFetch } from '../../adalConfig';
import { usersConfig } from './usersConfig';
import axios from 'axios';
import { ORDER_ASC, ORDER_DEFAULT, USER_SIZE } from '../../utils/userConstant';

export const getUser = () => {
  const uri = usersConfig().api.get.url;
  return adalApiFetch(axios, uri, {}).then(res => {
    sessionStorage.setItem('current', JSON.stringify(res.data));
    return res.data;
  });
};

export const getUsers = (page, sort, order) => {
  const uri =
    usersConfig().api.users.url +
    '?page=' +
    page +
    '&size=' +
    USER_SIZE +
    '&sort=' +
    sort +
    ',' +
    order;
  return adalApiFetch(axios, uri, {}).then(res => {
    const totalCount = parseInt(res.headers['x-total-count'], 10);
    const nbPage = Math.ceil(totalCount / USER_SIZE);
    return { users: res.data, nbPage, totalCount };
  });
};

export const getUserByAd = ad => {
  const uri = `${usersConfig().api.users.url}/${ad}`;
  return adalApiFetch(axios, uri, {}).then(res => {
    return { users: res.data ? [res.data] : [], totalCount: 1, nbPage: 1 };
  });
};

export const getUsersByCompany = (
  company,
  page = 0,
  sort = ORDER_DEFAULT,
  order = ORDER_ASC
) => {
  const uri = `${
    usersConfig().api.users.url
  }/?company=${company}&size=${USER_SIZE}&page=${page}&sort=${sort},${order}`;
  return adalApiFetch(axios, uri, {}).then(res => {
    const totalCount = parseInt(res.headers['x-total-count'], 10);
    const nbPage = Math.ceil(totalCount / USER_SIZE);
    return { users: res.data, nbPage, totalCount };
  });
};

export const getAllPagesUsersByCompany = (company, userSize) => {
  const uri = `${usersConfig().api.users.url}/?company=${company}&size=${userSize}`;
  return adalApiFetch(axios, uri, {}).then(res => {
    return { users: res.data };
  });
};

export const getAllPagesUsers = userSize => {
  const uri = usersConfig().api.users.url + '?size=' + userSize;
  return adalApiFetch(axios, uri, {}).then(res => {
    console.log('api', res.data.users);
    return { users: res.data };
  });
};

export const createUser = (
  ad,
  applications,
  approver,
  mail,
  lastName = null,
  country = null,
  firstName = null,
  role,
  city = null,
  language,
  company
) => {
  const uri = usersConfig().api.users.url;
  const body = {
    ad,
    applications: applications.map(appCode => ({ code: appCode })),
    approver,
    city,
    country,
    firstName,
    lastName,
    mail,
    role,
    language,
    company
  };

  return adalApiFetch(axios, uri, {
    method: 'POST',
    data: body
  }).then(res => {
    return res;
  });
};

export const updateUser = ({ initialAd, ...user }) => {
  const uri = usersConfig().api.update.url + initialAd;

  return adalApiFetch(axios, uri, {
    method: 'PUT',
    data: user
  }).then(res => {
    return res;
  });
};

export const updateLanguage = language => {
  const uri = usersConfig().api.language.url;

  return adalApiFetch(axios, uri, {
    method: 'PUT',
    data: language
  }).then(res => {
    return res;
  });
};

export const searchUsers = (searchType, keyword, page = 0) => {
  const uri = `${
    usersConfig().api.searchUsers.url
  }/?${searchType}=${keyword}&size=10&page=${page}`;
  return adalApiFetch(axios, uri, {}).then(res => {
    return { data: res.data, totalCount: res.headers['x-total-count'] };
  });
};

export const importUsers = fileToSend => {
  const formData = new FormData();
  formData.append('file', fileToSend);

  const uri = `${usersConfig().api.import.url}`;

  return adalApiFetch(axios, uri, {
    method: 'POST',
    data: formData,
    headers: { 'Content-Type': 'application/form-data' }
  }).then(res => {
    return res;
  });
};

export const exportUsers = (role, company, status, applications) => {
  const uri = `${
    usersConfig().api.export.url
  }?role=${role}&company=${company}&activated=${status}&applications=${applications.toString()}`;

  return adalApiFetch(axios, uri, { responseType: 'blob' }).then(res => {
    return { data: res.data, content: res.headers['content-disposition'] };
  });
};

export const getCompanies = () => {
  const uri = `${
    usersConfig().api.searchCompanies.url
  }/?size=100000`;
  return adalApiFetch(axios, uri, {}).then(res => {
    return res.data;
  });
};

export const searchCompanies = (keyword, page = 0) => {
  const uri = `${
    usersConfig().api.searchCompanies.url
  }/?company=${keyword}&size=10&page=${page}`;
  return adalApiFetch(axios, uri, {}).then(res => {
    return { data: res.data, totalCount: res.headers['x-total-count'] };
  });
};

export const activateUsers = users => {
  const uri = usersConfig().api.activate.url;

  return adalApiFetch(axios, uri, {
    method: 'PUT',
    data: users
  }).then(res => {
    return res;
  });
};

export const deactivateUsers = users => {
  const uri = usersConfig().api.deactivate.url;

  return adalApiFetch(axios, uri, {
    method: 'PUT',
    data: users
  }).then(res => {
    return res;
  });
};

export const deleteUsers = users => {
  const uri = usersConfig().api.remove.url;

  return adalApiFetch(axios, uri, {
    method: 'POST',
    data: users
  }).then(res => {
    return res;
  });
};
