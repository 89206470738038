import { useRef, useCallback } from 'react';

export function useDebouncedCallback(callback, delay, options = {}) {
  const functionTimeoutHandler = useRef(null);
  const debouncedFunction = useRef(callback);
  debouncedFunction.current = callback;

  const debouncedCallback = useCallback(
    (...args) => {
      clearTimeout(functionTimeoutHandler.current);

      functionTimeoutHandler.current = setTimeout(() => {
        debouncedFunction.current(...args);
      }, delay);
    },
    [delay]
  );

  return [debouncedCallback];
}
