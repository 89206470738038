import React from 'react';
import PropTypes from 'prop-types';
import UnknowPageIcon from '../icons/unknowPageIcon';

const ErrorPage = ({ is404 }) => {
  return (
    <>
      <div className="ErrorPage-messageContainer">
        {is404 ? (
          <div className="ErrorPage-unknowPageContainer">
            <UnknowPageIcon />
            <div className="ErrorPage-message">
              PLEASE CONTACT{' '}
              <a
                className="ErrorPage-link"
                href="mailto:ga-fr-support.backofficemode@chanel.com"
              >
                THE SUPPORT TEAM
              </a>{' '}
              FOR MORE INFORMATION
            </div>
          </div>
        ) : (
          <div className="ErrorPage-message">
            PLEASE CONTACT{'  '}
            <a
              className="ErrorPage-link"
              href="mailto:ga-fr-support.backofficemode@chanel.com"
            >
              THE SUPPORT TEAM
            </a>{' '}
            FOR MORE INFORMATION
          </div>
        )}
      </div>
    </>
  );
};

ErrorPage.propTypes = {
  is404: PropTypes.bool
};
ErrorPage.displayName = 'ErrorPage';
export default ErrorPage;
