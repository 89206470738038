import React, { useState, useEffect, useContext } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { useForm } from '../../utils/useForm';
import { CrossIcon } from '../../app/icons/crossIcon';
import { getRoles } from '../../services/roles/rolesApi';
import { getApplications } from '../../services/applications/applicationsApi';
import { UserTab } from './userTab';
import { AccessTab } from './accessTab';
import { createUser, updateUser } from '../../services/users/usersApi';
import { NotificationContext } from '../../app';
import { arraysEqual } from '../../utils/arraysEquals';
import { ActivityTab } from './activityTab';

const CreateEdit = ({ modalIsOpen, user, onClose, reloadUserList }) => {
  const { displayNotifictaion } = useContext(NotificationContext);
  const [selectedTab, setSelectedTab] = useState('user');
  const [roles, setRoles] = useState([]);
  const [applications, setApplications] = useState([]);
  const [selectedApps, setSelectedApps] = useState([]);
  const [initialSelectedApps, setInitialSelectedApps] = useState([]);
  const [accessTabError, setAccessTabError] = useState(false);
  const [resetWizard, setResetWizard] = useState(false);
  const [initialAd, setInitialAd] = useState();

  useEffect(() => {
    const roles = async () => {
      const result = await getRoles();
      setRoles(result);
    };
    const loadApplications = async () => {
      try {
        const data = await getApplications();
        setApplications(data);
      } catch (e) {
        setApplications([]);
      }
    };
    loadApplications();
    roles();
  }, []);

  const onSelectApp = code => () => {
    setAccessTabError(false);
    if (isInSelectedApps(code)) {
      setSelectedApps(selectedApps.filter(t => t !== code));
    } else {
      setSelectedApps([...selectedApps, code]);
    }
  };

  const languages = [
    { name: 'Francais', code: 'fr-FR' },
    { name: 'English', code: 'en-US' },
    { name: 'Italiano', code: 'it-IT' }
  ];

  const stateSchema = {
    ad: {
      value: '',
      error: '',
      required: true,
      validator: {
        regEx: /^([a-zA-Z0-9_-]){3,16}$/gm,
        error: "L'ad est invalide"
      }
    },
    mail: {
      required: true,
      value: '',
      error: '',
      validator: {
        regEx: /^[a-z0-9]+([|.|_|!|#|$|%|&|‘|'|*|+|/|=|?|^|`|{|||}|~|–|-]{1}[a-z0-9]+)*@[a-z0-9]+([|.|_|!|#|$|%|&|‘|'|*|+|/|=|?|^|`|{|||}|~|–|-]{1}[a-z0-9]+)*[.]{1}[a-z]{2,6}$/g,
        error: 'Le mail est invalide'
      }
    },
    approver: {
      required: true,
      value: '',
      error: '',
      validator: {
        regEx: /^[a-z0-9]+([|.|_|!|#|$|%|&|‘|'|*|+|/|=|?|^|`|{|||}|~|–|-]{1}[a-z0-9]+)*@[a-z0-9]+([|.|_|!|#|$|%|&|‘|'|*|+|/|=|?|^|`|{|||}|~|–|-]{1}[a-z0-9]+)*[.]{1}[a-z]{2,6}$/g,
        error: 'Approbateur invalide'
      }
    },
    role: { value: '', error: '', required: true },
    language: { value: '' },
    firstname: { value: '' },
    lastname: { value: '' },
    company: { value: '', error: '', required: true },
    country: { value: '' },
    city: { value: '' },
    selectedLanguage: { value: '' }
  };

  const submitUser = async () => {
    try {
      if (user) {
        await updateUser({
          initialAd,
          ad: state.ad.value,
          approver: state.approver.value,
          mail: state.mail.value,
          lastName: state.lastname.value,
          country: state.country.value,
          firstName: state.firstname.value,
          role: state.role.value,
          city: state.city.value,
          language: state.language.value,
          company: state.company.value,
          applications: selectedApps.map(appCode => ({ code: appCode })),
          activated: user.activated
        });
        displayNotifictaion('Modification enregistrée');
        closeAndReset(true);
      } else {
        await createUser(
          state.ad.value,
          selectedApps,
          state.approver.value,
          state.mail.value,
          state.lastname.value,
          state.country.value,
          state.firstname.value,
          state.role.value,
          state.city.value,
          state.language.value,
          state.company.value
        );
        displayNotifictaion('Utilisateur créé');
        closeAndReset();
      }
    } catch (error) {
      if (error.response.status === 409) {
        setSelectedTab('user');
        setErrorField("L'ad doit être unique", 'ad');
        return;
      }

      if (
        error.response.status === 403 &&
        error.response.data.code === 'NOT_ENOUGH_PRIVILEGES_TO_UPDATE_USER'
      ) {
        displayNotifictaion(
          "Vous n'avez pas les droits suffisants pour effectuer cette action",
          'error'
        );
        return;
      }
      console.error(error);
    }
  };

  const {
    state,
    handleChange,
    onValidate,
    validateAllFields,
    handleReset,
    handleSubmit,
    isFormEmpty,
    isFormInerror,
    setErrorField,
    setInitialState,
    isPristine
  } = useForm(stateSchema, submitUser);

  useEffect(() => {
    if (user && modalIsOpen) {
      const copySchema = { ...stateSchema };
      copySchema.ad.value = user.ad;
      copySchema.mail.value = user.mail;
      copySchema.firstname.value = user.firstName;
      copySchema.lastname.value = user.lastName;
      copySchema.city.value = user.city;
      copySchema.country.value = user.country;
      copySchema.company.value = user.company;
      copySchema.approver.value = user.approver;
      copySchema.role.value = user.role.code;
      copySchema.language.value = user.language;
      const copySelectedApps = [
        ...(user.applications
          ? user.applications.filter(({ code }) => code !== 'ADM').map(({ code }) => code)
          : [])
      ];
      setInitialAd(copySchema.ad.value);
      setInitialState(copySchema);
      setInitialSelectedApps(copySelectedApps);
      setSelectedApps(copySelectedApps);
    }
  }, [user, resetWizard, modalIsOpen]);

  const onSubmit = e => {
    e.preventDefault();
    if (selectedTab === 'access' && selectedApps.length === 0) {
      setAccessTabError(true);
      return;
    }

    if (user && !isFormInerror()) {
      const saveConfirm = window.confirm(
        "Êtes-vous sûr de vouloir modifier l'utilisateur suivant ?"
      );
      if (saveConfirm) {
        handleSubmit();
      } else {
        return;
      }
    }
    handleSubmit();
  };

  const next = e => {
    e.preventDefault();
    validateAllFields();
    if (!isFormInerror()) {
      setSelectedTab('access');
    }
  };

  const closeAndReset = (save = false) => {
    handleReset();
    setSelectedApps([]);
    setSelectedTab('user');
    setAccessTabError(false);
    onClose();
    if (initialAd !== state.ad.value && save) {
      reloadUserList(state.ad.value);
    } else {
      reloadUserList();
    }
  };

  const closeModal = () => {
    if (!user && !isFormEmpty()) {
      const exitConfirm = window.confirm(
        'Êtes-vous sûr de vouloir quitter sans sauvegarder ?'
      );
      if (exitConfirm) {
        closeAndReset();
      }
      return;
    }

    if (user) {
      if (!isPristine() || !isSelectedAppsPristine()) {
        const exitConfirm = window.confirm(
          'Êtes-vous sûr de vouloir quitter sans sauvegarder ?'
        );
        if (exitConfirm) {
          closeAndReset();
        }
        return;
      }
    }
    closeAndReset();
  };

  const onSelectTab = tabType => () => {
    if (!user) {
      if (tabType === 'activity') {
        return;
      }
      if (isFormInerror()) {
        return;
      }
    }
    if (user) {
      if (!isPristine() || !isSelectedAppsPristine()) {
        const exitConfirm = window.confirm(
          'Êtes-vous sûr de vouloir quitter sans sauvegarder ?'
        );
        if (exitConfirm) {
          if (tabType !== selectedTab) {
            setAccessTabError(false);
            setResetWizard(!resetWizard);
          }
          setSelectedTab(tabType);
        }
        return;
      }
    }
    setSelectedTab(tabType);
  };

  const isInSelectedApps = code => {
    return selectedApps.includes(code);
  };

  const isActiveClass = tabType => {
    return tabType === selectedTab ? 'createEdit__tabs__item--active' : '';
  };

  const isEnabledCLass = tabType => {
    if (!user) {
      if (tabType === 'access') {
        return isFormInerror() ? 'createEdit__tabs__item--disabled' : '';
      }
      if (tabType === 'activity') {
        return 'createEdit__tabs__item--disabled';
      }
      return '';
    }
  };

  const isSelectedAppsPristine = () => {
    return arraysEqual(selectedApps, initialSelectedApps);
  };

  const onRoleChange = e => {
    const value = e.target.value;
    if (!user) {
      if (value !== 'ADMINISTRATEUR' && value !== 'SUPERADMINISTRATEUR') {
        setSelectedApps([]);
      }
    }

    if (value === 'ADMINISTRATEUR' || value === 'SUPERADMINISTRATEUR') {
      setSelectedApps([...applications.map(app => app.code)]);
    }
    setAccessTabError(false);
    handleChange(e);
  };

  return (
    <ReactModal
      className="createEdit"
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      overlayClassName="modalOverlay"
      shouldCloseOnOverlayClick={true}
    >
      <div className="createEdit__header">
        <h1 className="createEdit__title">Utilisateurs</h1>
        <CrossIcon data-testid="close" className="createEdit__close" onClick={closeModal} />
      </div>
      <div className="createEdit__tabs">
        <span
          onClick={onSelectTab('user')}
          className={`createEdit__tabs__item ${isActiveClass('user')}`}
          data-testid="user"
        >
          utilisateur
        </span>
        <span
          onClick={onSelectTab('access')}
          className={`createEdit__tabs__item ${isActiveClass('access')} ${isEnabledCLass(
            'access'
          )}`}
          data-testid="access"
        >
          accès
        </span>
        <span
          onClick={onSelectTab('activity')}
          className={`createEdit__tabs__item ${isActiveClass('activity')} ${isEnabledCLass(
            'activity'
          )}`}
          data-testid="activity"
        >
          activité
        </span>
      </div>
      <form data-testid="userModal">
        {selectedTab === 'activity' && (
          <ActivityTab
            creationDate={user.creationDate}
            lastConnectionDate={user.lastConnectionDate}
          />
        )}
        {selectedTab === 'access' && (
          <AccessTab
            applications={applications}
            isInSelectedApps={isInSelectedApps}
            onSelectApp={onSelectApp}
            accessTabError={accessTabError}
            onSubmit={onSubmit}
            disabled={user && isSelectedAppsPristine()}
          />
        )}
        {selectedTab === 'user' && (
          <UserTab
            user={user}
            state={state}
            onRoleChange={onRoleChange}
            handleChange={handleChange}
            onValidate={onValidate}
            roles={roles}
            languages={languages}
            onSubmit={user ? onSubmit : next}
            disabled={user ? isPristine() : isFormEmpty(true)}
          />
        )}
      </form>
    </ReactModal>
  );
};

CreateEdit.propTypes = {
  modalIsOpen: PropTypes.bool,
  user: PropTypes.object,
  onClose: PropTypes.func,
  reloadUserList: PropTypes.func
};

CreateEdit.displayName = 'CreateEdit';
export default CreateEdit;
