import { AuthenticationContext } from 'react-adal';

export let authContext = null;
export const config = {};

export function adalConfig() {
  return {
    instance: 'https://cid.clasp-infra.com/',
    tenant: 'adfs',
    clientId: config.clientId,
    postLogoutRedirectUri: window.location.origin,
    cacheLocation: 'sessionStorage',
    redirectUri: window.location.origin,
    extraQueryParameter: 'response_mode=fragment',
    endpoints: {
      api: config.apimUrl
    }
  };
}

export function setAuthContext() {
  authContext = new AuthenticationContext(adalConfig());
}

export const adalApiFetch = (fetch, url, options) => {
  if (config.name === 'dev') {
    return configHeaders(fetch, url, options, config.token);
  } else {
    return adalFetch(authContext, adalConfig().endpoints.api, fetch, url, options);
  }
};

function adalGetToken(authContext, resourceGuiId) {
  return new Promise(function(resolve) {
    authContext.acquireToken(resourceGuiId, function(message, token, msg) {
      !msg ? resolve(token) : authContext.login();
    });
  });
}

function adalFetch(authContext, resourceGuiId, fetch, url, options) {
  return adalGetToken(authContext, resourceGuiId).then(function(token) {
    return configHeaders(fetch, url, options, token);
  });
}

function configHeaders(fetch, url, options, token) {
  const o = options || {};
  if (!o.headers) o.headers = {};
  o.headers.Authorization = 'Bearer ' + token;
  o.headers['Ocp-Apim-Subscription-Key'] = config.subscriptionKey;
  return fetch(url, o);
}
