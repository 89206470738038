import React from 'react';
import PropTypes from 'prop-types';
import palette from '../../assets/scss/_palette.scss';

const ArrowPreviousIcon = ({ width = '24px', height = '24px', className }) => (
  <svg
    width={width}
    height={height}
    fill={palette.greyTxt}
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

ArrowPreviousIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string
};
ArrowPreviousIcon.displayName = 'ArrowPreviousIcon';
export default ArrowPreviousIcon;
