import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import AngleDownIcon from '../../app/icons/angleDownIcon';

export const CustomCheckbox = ({
  checked,
  onClick,
  menu,
  partialyChecked = false,
  testId
}) => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideMenu);
    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, []);

  const handleClickOutsideMenu = event => {
    if (menuRef.current && menuRef.current.contains(event.target)) {
      return;
    }
    setDisplayMenu(false);
  };

  const onDisplayMenu = () => {
    setDisplayMenu(!displayMenu);
  };

  const onCloseMenu = () => {
    setDisplayMenu(false);
  };

  return (
    <div className="customCheckbox">
      <div data-testid={testId} onClick={onClick} className="customCheckbox__container">
        {checked && !partialyChecked && (
          <div data-testid="checked" className="customCheckbox__container--checked"></div>
        )}
        {partialyChecked && (
          <div
            data-testid="partialyChecked"
            className="customCheckbox--partiallyChecked"
          ></div>
        )}
      </div>
      <AngleDownIcon
        data-testid={`${menu && 'openSelectMenu'}`}
        onClick={onDisplayMenu}
        className={
          menu
            ? 'customCheckbox__arrow'
            : 'customCheckbox__arrow customCheckbox__arrow--hidden'
        }
      />
      {menu && displayMenu && menu(onCloseMenu, menuRef)}
    </div>
  );
};
CustomCheckbox.propTypes = {
  checked: PropTypes.bool,
  partialyChecked: PropTypes.bool,
  onClick: PropTypes.func,
  menu: PropTypes.func,
  testId: PropTypes.string
};
