import React from 'react';
import PropTypes from 'prop-types';
import { CrossIcon } from '../icons/crossIcon';

export const Dropdown = ({
  value,
  itemList,
  onChange,
  onBlur,
  className,
  name,
  placeholder,
  testId,
  disabled,
  errorMessage
}) => {
  const isStringArray = itemList.filter(t => t.name && t.code).length === 0;
  return isStringArray ? (
    <div className={`dropdown__container ${className && className}`}>
      {value && <label className="dropdown__passivePlaceholder">{placeholder}</label>}
      <select
        className="dropdown"
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        data-testid={testId}
      >
        {placeholder && (
          <option className="placeholder" value="">
            {placeholder}
          </option>
        )}
        {itemList.map((item, index) => {
          return (
            <option value={item} key={index}>
              {item}
            </option>
          );
        })}
      </select>
      {errorMessage && (
        <div className="dropdown__error__container">
          <CrossIcon className="dropdown__error__icon" />
          <label data-testid={`${testId}-error`} className="dropdown__errorMessage">
            {errorMessage}
          </label>
        </div>
      )}
    </div>
  ) : (
    <div className={`dropdown__container ${className && className}`}>
      {value && placeholder && (
        <label className="dropdown__passivePlaceholder">{placeholder}</label>
      )}
      <select
        className="dropdown"
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        data-testid={testId}
      >
        {placeholder && (
          <option className="placeholder" value="">
            {placeholder}
          </option>
        )}
        {itemList.map((item, index) => {
          return (
            <option value={item.code} key={index}>
              {item.name}
            </option>
          );
        })}
      </select>
      {errorMessage && (
        <div className="dropdown__error__container">
          <CrossIcon className="dropdown__error__icon" />
          <label data-testid={`${testId}-error`} className="dropdown__errorMessage">
            {errorMessage}
          </label>
        </div>
      )}
    </div>
  );
};

Dropdown.displayName = 'Dropdown';
Dropdown.propTypes = {
  value: PropTypes.string,
  itemList: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  testId: PropTypes.string,
  disabled: PropTypes.bool
};
