import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CrossIcon } from '../icons/crossIcon';

export const TextField = ({
  value,
  onChange,
  onBlur,
  name,
  placeholder,
  className,
  testId,
  inputRef,
  errorMessage
}) => {
  const [placeholderUp, setPlaceholderUp] = useState(false);

  useEffect(() => {
    if (value) {
      setPlaceholderUp(true);
    } else {
      setPlaceholderUp(false);
    }
  }, [value]);

  return (
    <div className={`textfield__container ${className}`}>
      {placeholderUp && <label className="textfield__passivePlaceholder">{placeholder}</label>}
      <input
        type="text"
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        onBlur={onBlur}
        className="textfield"
        ref={inputRef}
        data-testid={testId}
      />
      {errorMessage && (
        <div className="textfield__error__container">
          <CrossIcon className="textfield__error__icon" />
          <label data-testid={`${name}-error`} className="textfield__errorMessage">
            {errorMessage}
          </label>
        </div>
      )}
    </div>
  );
};

TextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  testId: PropTypes.string,
  errorMessage: PropTypes.string,
  inputRef: PropTypes.object
};

TextField.displayName = 'TextField';
