import React from 'react';
import PropTypes from 'prop-types';
import CancelButton from '../cancelButton/cancelButton';
import SubmitButton from '../submitButton/submitButton';

const FormButtons = ({ labelCancel, labelSubmit, onCancel, disabled }) => {
  return (
    <div className="FormButtons">
      {onCancel && <CancelButton labelCancel={labelCancel} onClick={onCancel} />}
      <SubmitButton labelSubmit={labelSubmit} disabled={disabled} />
    </div>
  );
};

FormButtons.propTypes = {
  labelCancel: PropTypes.string,
  labelSubmit: PropTypes.string,
  onCancel: PropTypes.func,
  disabled: PropTypes.bool
};
FormButtons.displayName = 'FormButtons';
export default FormButtons;
