import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { SELECT_ALL, SELECT_ALL_PAGES, UNSELECT_ALL } from './reducer';
import { getAllPagesUsersByCompany, getAllPagesUsers } from '../../services/users/usersApi';

export const SelectUsersMenu = ({ close, menuRef, searchType, company, totalCount }) => {
  const dispatch = useDispatch();
  const allUsersAreSelected = useSelector(state => state.tableUser.selectedAll);
  const selectAll = useCallback(() => dispatch({ type: SELECT_ALL }), []);
  const unSelectAll = useCallback(() => dispatch({ type: UNSELECT_ALL }), []);

  const onSelect = clickType => async () => {
    if (clickType === 'all' && !allUsersAreSelected) {
      selectAll();
    }
    if (clickType === 'none' && allUsersAreSelected) {
      unSelectAll();
    }
    if (clickType === 'allPages') {
      let data;
      if (searchType === 'company') {
        data = await getAllPagesUsersByCompany(company, totalCount);
      } else {
        data = await getAllPagesUsers(totalCount);
      }
      dispatch({ type: SELECT_ALL_PAGES, data: data.users.map(({ ad }) => ad) });
    }
    close();
  };

  return (
    <div data-testid="selectMenu" ref={menuRef} className="customCheckbox__arrow__menu">
      <div
        data-testid="selectMenuAll"
        onClick={onSelect('all')}
        className="customCheckbox__arrow__menu__item"
      >
        <span>Tous</span>
      </div>
      <div
        data-testid="selectMenuNone"
        onClick={onSelect('none')}
        className="customCheckbox__arrow__menu__item"
      >
        <span>Aucun</span>
      </div>
      <div
        data-testid="selectMenuAllPages"
        onClick={onSelect('allPages')}
        className="customCheckbox__arrow__menu__item"
      >
        <span>Toutes les pages</span>
      </div>
    </div>
  );
};

SelectUsersMenu.displayName = 'SelectUsersMenu';
SelectUsersMenu.propTypes = {
  close: PropTypes.func,
  menuRef: PropTypes.object,
  searchType: PropTypes.string,
  company: PropTypes.string,
  totalCount: PropTypes.number
};
