import { adalApiFetch } from '../../adalConfig';
import { applicationsConfig } from './applicationsConfig';
import axios from 'axios';

export const getApplications = () => {
  const uri = applicationsConfig().api.applications.url;
  return adalApiFetch(axios, uri, {}).then(res => {
    return res.data;
  });
};

export const createApplication = (
  name,
  url,
  applicationDescriptions,
  applicationWarnings,
  warning,
  visible,
  sso,
  state
) => {
  const uri = applicationsConfig().api.applications.url;
  const body = {
    name,
    url,
    applicationDescriptions,
    applicationWarnings,
    warning,
    visible,
    sso,
    state
  };

  return adalApiFetch(axios, uri, {
    method: 'POST',
    data: body
  }).then(res => {
    return res;
  });
};

export const updateApplication = (
  name,
  url,
  applicationDescriptions,
  applicationWarnings,
  code,
  warning,
  visible,
  sso,
  state
) => {
  const uri = `${applicationsConfig().api.applications.url}/${code}`;
  const body = {
    name,
    url,
    code,
    applicationDescriptions,
    applicationWarnings,
    warning,
    visible,
    sso,
    state
  };

  return adalApiFetch(axios, uri, {
    method: 'PUT',
    data: body
  }).then(res => {
    return res;
  });
};

export const deleteApplication = code => {
  const uri = `${applicationsConfig().api.applications.url}/${code}`;

  return adalApiFetch(axios, uri, {
    method: 'DELETE'
  }).then(res => {
    return res.data;
  });
};
