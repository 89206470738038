import React, { useEffect, useState, useContext } from 'react';
import { getRoles } from '../../services/roles/rolesApi';
import { getApplications } from '../../services/applications/applicationsApi';
import { exportUsers, getCompanies } from '../../services/users/usersApi';
import FileSaver from 'file-saver';
import FormButtons from '../../app/formButtons/formButtons';
import { Layout } from '../../app/layout';
import { Dropdown } from '../../app/dropdown/dropdown';
import { Title } from '../../app/title/title';
import { NotificationContext } from '../../app';

const ExportUser = () => {
  const { displayNotifictaion } = useContext(NotificationContext);
  const [applications, setApplications] = useState([]);
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [status] = useState([
    { name: 'Actif', code: true },
    { name: 'Non Actif', code: false }
  ]);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedApplis, setSelectedApplis] = useState([]);

  useEffect(() => {
    const roles = async () => {
      const result = await getRoles(true);
      setRoles(result);
    };
    roles();
  }, []);

  useEffect(() => {
    const applications = async () => {
      const result = await getApplications();
      setApplications(result);
    };
    applications();
  }, []);

  useEffect(() => {
    const companies = async () => {
      const result = await getCompanies();
      setCompanies(result);
    };
    companies();
  }, []);

  const onSelectRole = ({ target: { value } }) => {
    setSelectedRole(value);
  };

  const onSelectCompany = ({ target: { value } }) => {
    setSelectedCompany(value);
  };

  const onSelectStatus = ({ target: { value } }) => {
    setSelectedStatus(value);
  };

  const onSelectAppli = value => () => {
    if (selectedApplis.filter(code => code === value).length > 0) {
      setSelectedApplis(selectedApplis.filter(code => code !== value));
    } else {
      setSelectedApplis([
        ...selectedApplis,
        applications.find(({ code }) => code === value).code
      ]);
    }
  };

  const getClassAppli = value =>
    `export-user-selection-applications-appli${
      selectedApplis.filter(code => code === value).length > 0 ? ' selected' : ''
    }`;

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const res = await exportUsers(
        selectedRole,
        selectedCompany,
        selectedStatus,
        selectedApplis
      );
      FileSaver.saveAs(res.data, res.content.replace(new RegExp('.*' + 'filename='), ''));
      displayNotifictaion('Fichier exporté', 'success');
    } catch (error) {
      displayNotifictaion("Une erreur s'est produite lors de l'export", 'error');
    }
  };

  const onCancel = () => {
    setSelectedStatus('');
    setSelectedApplis([]);
    setSelectedCompany('');
    setSelectedRole('');
  };

  return (
    <Layout>
      <div className={'export-user'}>
        <Title title="Export" subTitle="Export d'une liste d'utilisateurs par filtrage" />
        <form className="export-user__form" onSubmit={onSubmit}>
          <div className={'export-user-selection'}>
            <span className={'export-user-selection-title'}>Options d&apos;export</span>
            <span className={'export-user-selection-subTitle'}>Selection d&apos;outils</span>
            <div className={'export-user-selection-applications'}>
              {applications.map((appli, index) => {
                return (
                  <div
                    data-testid={`appli-${index}`}
                    className={getClassAppli(appli.code)}
                    onClick={onSelectAppli(appli.code)}
                    key={index}
                  >
                    {appli.name}
                  </div>
                );
              })}
            </div>
            <Dropdown
              value={selectedCompany}
              itemList={companies}
              onChange={onSelectCompany}
              name="company"
              className={'export-user-selection-select'}
              placeholder="Selectionnez une compagnie..."
              testId="company"
            />
            <Dropdown
              value={selectedRole}
              itemList={roles}
              onChange={onSelectRole}
              name="role"
              placeholder="Selectionnez un rôle..."
              className={'export-user-selection-select'}
              testId="role"
            />
            <Dropdown
              value={selectedStatus}
              itemList={status}
              onChange={onSelectStatus}
              name="status"
              placeholder="Selectionnez un statut..."
              className={'export-user-selection-select'}
              testId="status"
            />
          </div>
          <div className={'export-user-buttons'}>
            <FormButtons labelCancel="annuler" labelSubmit="exporter" onCancel={onCancel} />
          </div>
        </form>
      </div>
    </Layout>
  );
};

ExportUser.displayName = 'ExportUser';
export default ExportUser;
