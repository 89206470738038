import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CrossIcon } from '../icons/crossIcon';

export const TextArea = ({
  value,
  rows,
  onChange,
  onBlur,
  name,
  placeholder,
  className,
  testId,
  inputRef,
  errorMessage
}) => {
  const [placeholderUp, setPlaceholderUp] = useState(false);

  useEffect(() => {
    if (value) {
      setPlaceholderUp(true);
    } else {
      setPlaceholderUp(false);
    }
  }, [value]);

  return (
    <div className={`textArea__container ${className}`}>
      {placeholderUp && <label className="textArea__passivePlaceholder">{placeholder}</label>}
      <textarea
        rows={rows}
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        onBlur={onBlur}
        className="textArea"
        ref={inputRef}
        data-testid={testId}
      />
      {errorMessage && (
        <div className="textArea__error__container">
          <CrossIcon className="textArea__error__icon" />
          <label data-testid={`${name}-error`} className="textArea__errorMessage">
            {errorMessage}
          </label>
        </div>
      )}
    </div>
  );
};

TextArea.propTypes = {
  value: PropTypes.string,
  rows: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  testId: PropTypes.string,
  errorMessage: PropTypes.string,
  inputRef: PropTypes.object
};

TextArea.displayName = 'textArea';
